import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import moment from "moment";
import CryptoJS from "crypto-js";
import db from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import UserExperior from "user-experior-web";

const AES_ENCRYPTION_KEY = process.env.REACT_APP_AES_SECRET_KEY
const encryptFlag = process.env.REACT_APP_ENCRYPT_FLAG == '1'
const AES_BLOCK_SIZE = 16;

async function customFetch(url, options) {
  if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
    const ue = new UserExperior();
    ue.startRecording(Cookies.get("parameter_value"), {
      sessionReplay: {
        maskAllInputs: false,
        maskInputOptions: {
          password: false,
          email: false,
          tel: false,
          color: false,
          date: false,
          "datetime-local": false,
          month: false,
          number: false,
          range: false,
          search: false,
          text: false,
          time: false,
          url: false,
          week: false,
          textarea: false,
          select: false,
        },
      },
    });
    ue.setUserIdentifier(
      `${localStorage.getItem("in_username")}_${
        process.env.REACT_APP_ENVIRONMENT
      }`
    );

    let body = JSON.stringify(options.body);

    let urlName = url.split("/");

    let eventLog = {
      body: body,
      headers: JSON.stringify(options.headers),
      method: options.method,
      url: url,
    };
    ue.logEvent(urlName[urlName.length - 1], eventLog);
  }
  if(encryptFlag){
    url = encrypt_get_params(url);
    let contentType = options.headers ? options.headers["Content-Type"] : undefined
  
    if (!contentType && options.body) {
      if (options.body instanceof FormData) {
        contentType = 'multipart/form-data';
      } else if (typeof options.body === 'object') {
        contentType = 'application/json'
      } else if (typeof options.body === 'string') {
        if(isvalidjson(options.body)) contentType = 'application/json'
        else if(isUrlEncoded(options.body)) contentType = 'application/x-www-form-urlencoded';
        else contentType = 'text/plain'
      }
    }
  
    if (options.body) {
      const encryptedBody = await encrypt_post_data(options.body, contentType);
  
      if(contentType === 'application/json'){
        const data = {
          encrypted_data: encryptedBody
        }
        options.body = JSON.stringify(data)
      }
      else{
        const formData = new FormData();
        formData.append("encrypted_data", encryptedBody);
        options.body = formData
      }
  
      // if(options.headers) delete options.headers["Content-Type"]
      if(options.headers) {
        options.headers["Content-Type"] = options.headers["Content-Type"] || contentType
        if(contentType === 'multipart/form-data') delete options.headers["Content-Type"]
      }
    }
  }

  options.headers = options.headers || {};
  if ("api-token" in options.headers) {
    options.headers["api-token"] = localStorage.getItem("api_token");
    return await isTokenExpired(url, options);
  } else {
    return await call_api(url, options);
  }
}

const isUrlEncoded = (data) => {
  try {
    return decodeURIComponent(data) !== data;
  } catch (e) {
    return false;
  }
};

const encrypt_get_params = (url) => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);

  params.forEach((value, key) => {
    const encryptedValue = encrypt_data_aes(value);
    params.set(key, encryptedValue);
  });

  urlObj.search = params.toString();

  return urlObj.toString();
};

const encrypt_post_data = async (bodySrc, contentType) => {
  if (contentType.includes('application/json')) {
    try {
      const request_body_parsed = typeof bodySrc === 'string' ? JSON.parse(bodySrc) : bodySrc;
      return encrypt_data_aes(JSON.stringify(request_body_parsed));
    } catch (error) {
      console.error("Failed to parse JSON body:", error);
      throw new Error("Invalid JSON body");
    }

  } else if (contentType.includes('multipart/form-data')) {
    console.log("Encrypting formdata payload", bodySrc);

    const jsonObject = {};
    const fileConversionPromises = []
    const filesConverted = []
    for (const [key, value] of bodySrc.entries()) {
      if (value instanceof File) {
        fileConversionPromises.push(
          file_to_base64(value).then(base64Data => {
            const mime_type = value.type || 'application/octet-stream';
            filesConverted.push({
              content: base64Data,
              filename: value.name,
              filetype: mime_type
            })
          })
        );
      } else {
        jsonObject[key] = value;
      }
    }
    await Promise.all(fileConversionPromises);
    if(filesConverted.length > 0){
      jsonObject["file"] = filesConverted
    }

    return encrypt_data_aes(JSON.stringify(jsonObject));

  } else if (contentType.includes('text/plain')) {
    return encrypt_data_aes(bodySrc);
  } else if (contentType.includes('application/x-www-form-urlencoded')) {
    const params = new URLSearchParams(bodySrc);
    let jsonConvertedBody = {};
    params.forEach((value, key) => {
      jsonConvertedBody[key] = value;
    });

    return encrypt_data_aes(JSON.stringify(jsonConvertedBody));
  }

  throw new Error("Unsupported Content-Type");
};

function encrypt_data_aes(data) {
  const keyBytes = CryptoJS.enc.Base64.parse(AES_ENCRYPTION_KEY)
  const iv = CryptoJS.lib.WordArray.random(AES_BLOCK_SIZE);

  const dataen = CryptoJS.AES.encrypt(data, keyBytes, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const combinedData = iv.concat(dataen.ciphertext)
  const hexEncoded = CryptoJS.enc.Hex.stringify(combinedData)
  
  return hexEncoded;
}

const file_to_base64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const isTokenExpired = async (url, options) => {
  const token = localStorage.getItem("api_token");

  if (options.skipRefresh) {
    console.log("Token expiry check skipped.");
    return await call_api(url, options);
  }

  if (token) {
    var decoded = jwt_decode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      return await generate_token(url, options);
    }
  }
  return await call_api(url, options)
};

const generate_token = async (url, options) => {
  const formdata = new FormData();
  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      if(encryptFlag){
        const data = {
          refresh_token: Cookies.get("refresh_token")
        }
        const reqData = encrypt_data_aes(JSON.stringify(data))
        formdata.append("encrypted_data", reqData);
      }
      else {
        formdata.append("refresh_token", Cookies.get("refresh_token"));
      }
      try {
        const response = await fetch(
          process.env.REACT_APP_URL + "/get/access/token",
          {
            method: "POST",
            body: formdata,
          }
        );

        if (response.ok) {
          const data3 = await response.json();
          console.log("access_token->", data3.access_token);
          localStorage.setItem("api_token", data3.access_token);
          options.headers["api-token"] = data3.access_token;
          return await call_api(url, options);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log("Error fetching token:", error);
      }
    }
  } else{
    Cookies.remove("api_token");
    localStorage.clear();
    window.location.href = "/";
  }
};
const isvalidjson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
const save_data = (url, options) => {
  let api_data = {};
  var jsonformdata = "";
  if (options.body) {
    if (isvalidjson(options.body)) {
      jsonformdata = options.body;
    } else {
      var object = {};
      options.body.forEach((value, key) => (object[key] = value));
      jsonformdata = JSON.stringify(object);
    }
  }

  const myDate = new Date();

  // Define the options for formatting
  const dateoptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  // Format the date
  const formattedDate = myDate.toLocaleString("en-GB", dateoptions);

  api_data.created_at = formattedDate;
  api_data.data_write_for = "API";
  api_data.component_name = options.headers.component || null;
  api_data.url = url || null;
  api_data.method = options.method || null;
  api_data.data = jsonformdata || null;
  api_data.apiId = options.headers.apiId || null;
  api_data.component_id = options.headers.component_id || null;
  api_data.api_group_id = options.headers.api_group_id || null;
  api_data.added_at = moment().unix();
  api_data.log_id = localStorage.getItem("api_token");
  api_data.user_id = localStorage.getItem("in_userid");

  let bucketName = process.env.REACT_APP_ENVIRONMENT + "_frontend_req_payload";
  //setTimeout(() => {
  addDoc(collection(db, bucketName), api_data).then((docRef) => {
    console.log("Document written with ID: ", docRef.id);
  });
  //}, 1000);
};
const call_api = async (url, options) => {
  save_data(url, options);
  console.log("call_api->", url);
  try {
    const response = await fetch(url, options);

    // if (!response.ok) {
    //   console.log("Network response was not ok:", response);
    //   if (response.status !== 200) {
    //     localStorage.clear();
    //     window.location.href = "/";
    //   }
    // }
    const jsonData = await response;
    if (
      Cookies.get("parameter_value") !== "null" &&
      Cookies.get("parameter_value") !== undefined
    ) {
      const ue = new UserExperior();
      ue.startRecording(Cookies.get("parameter_value"), {
        sessionReplay: {
          maskAllInputs: false,
          maskInputOptions: {
            password: false,
            email: false,
            tel: false,
            color: false,
            date: false,
            "datetime-local": false,
            month: false,
            number: false,
            range: false,
            search: false,
            text: false,
            time: false,
            url: false,
            week: false,
            textarea: false,
            select: false,
          },
        },
      });

      ue.logEvent("Response", {
        headers: JSON.stringify(jsonData),
        status: jsonData.status,
        url: jsonData.url,
      });
    }
    return jsonData;
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};

export default customFetch;