// AT-2102 | AT-2161 | Ankur Makavana | 18-07-2023 | Reports to have to 2 sub-sections: 1. Mobile Reports Desktop 2. MIS Reports Reports Master - to be greyed out for now Finance Reports - to be greyed out for now Business Reports
import React from "react";
import { logout, consolidate, filterData } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import moment from "moment";
import Cash_balance_report from "./Cash_balance_report";
import customFetch from "./apicall/api";
import { DatePicker, Select } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan, PESelect } from "./permissionComponents";
const { RangePicker } = DatePicker;

class Report_list extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      reportListOp_data: [],
      demand_sheet: [],
      isSpin: false,
      flag: 0,
      isErrOpen: false,
      report_data: [],
      report_config: [],
      loadButtonFlag: 0,
      lender: [],
      isValidData: "1",
      dropdownVal: "",
      dynamicDropdownData: false
    };

    this.setOption = this.setOption.bind(this);
  }

  // Ankur Makavana | 18-07-2023 | get report list based on sub categories
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    console.log(subcategories_id);
    const { api_token, subcategories_id } =
      (this.props.location && this.props.location.state) || {};
    this.setState({ isSpin: true });
    const formdata = new FormData();
    formdata.append("report_subcategory_id", subcategories_id);
    customFetch(process.env.REACT_APP_URL + "/get/report/list", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          reportList_data: data,
          isSpin: false,
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }
  // Ankur Makavana | 18-07-2023 | get request list of report
  get_report_data() {
    const { api_token, subcategories_id } =
      (this.props.location && this.props.location.state) || {};
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    // console.log(uid);
    const formdata = new FormData();
    formdata.append("userid", uid);
    formdata.append("report_id", this.state.report_list);
    customFetch(process.env.REACT_APP_URL + "/get/request/queue", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        const processed_AtCount = data.reduce((count, item) => {
          return item.processed_at === null ? count + 1 : count;
        }, 0);
        this.setState({
          processed_AtCount: processed_AtCount,
          report_data: data,
          isSpin: false,
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }
  // Ankur Makavana | 18-07-2023 | get report list based on sub categories
  get_reportconfig(e) {
    console.log("E-->", e);
    this.setState(
      {
        reportListOp_data: [],
        demand_sheet: [],
        isSpin: false,
        isErrOpen: false,
        report_data: [],
        report_config: [],
        loadButtonFlag: 0,
        lender: [],
        isValidData: "1",
        dropdownVal: "",
        dynamicDropdownData: false,
        report_list: e,
      },
      () => this.get_report_data()
    );
    const { api_token, subcategories_id } =
      (this.props.location && this.props.location.state) || {};
    if (e == 1) {
      this.setState({ isSpin: true });
      const formdata = new FormData();
      formdata.append("report_id", e);
      customFetch(process.env.REACT_APP_URL + "/get/report/parameter", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          if (
            data.api_code === 4 ||
            data.api_code === 3 ||
            data.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            demand_sheet: data,
            isSpin: false,
          });
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    } else {
      // const { api_token } =
      //   (this.props.location && this.props.location.state) || {};
      this.setState({
        isSpin: true,
      });

      customFetch(
        process.env.REACT_APP_URL + "/get/parameters?report_id=" + e,
        {
          method: "GET",
          headers: { "api-token": api_token },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then(async (data) => {
          console.log(data);
          if (
            data.api_code === 4 ||
            data.api_code === 3 ||
            data.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }

          if (data.length > 0) {
            data[0].report_config = JSON.parse(data[0].report_config);
            console.log("apidata->", data);
            // console.log(JSON.parse(data[0].report_config));
            // console.log(JSON.parse(data[0].report_config).length);
            // if (JSON.parse(data[0].report_config).length > 0) {

            // }
            //check inputsource has API
            let itr = 0
            data[0].report_config.forEach((item) => {
              if(item.inputtype === 'calendar_daterange' || item.inputtype === 'calendar_yearmonthrange'){
                item.mapId = itr
                itr += 2
              } else{
                item.mapId = itr
                itr += 1
              }
            })
            const hasAPIInputSource = data.some((item) => {
              const reportConfig = item.report_config;
              return reportConfig.some(
                (configItem) => configItem.inputsource === "API"
              );
            });
            if(hasAPIInputSource){
              const processedConfig = await Promise.all(
                data[0].report_config.map(async (item) => {
                  if(item.inputsource === "API" && item.inputtype != "textbox" && !item.parent_inputkey){
                    return await this.processReportConfig(item, data[0].report_id)
                  } else {
                    return item
                  }
                })
              )
              data[0].report_config = processedConfig
              this.setState({
                report_config: data,
                isSpin: false,
                loadButtonFlag: 0
              })
            } else{
              this.setState({
                report_config: data,
                isSpin: false,
                loadButtonFlag: 0,
              });
              console.log(data);
            }
          } else {
            this.setState({
              loadButtonFlag: 1,
              isSpin: false,
              report_config: [],
            });
          }
          this.setState({
            lender: [],
          });
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    }
  }
  processReportConfig = async (configItem, report_id) => {
    if (configItem.inputsource === "API") {
      console.log("process-->", processedItem)
      const processedItem = await this.fetchDataForInputKey(configItem, report_id)
      return processedItem
    }
    return configItem
  };
  async fetchDataForInputKey(item, report_id) {
    console.log("call fetchDataForInputKey", item);
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // Simulate fetching data from API
    try {
      let _body = {
        keyname: item.inputkey,
        params: [],
      };
      const response = await customFetch(
        process.env.REACT_APP_URL + "/reports/" + report_id,
        {
          method: "POST",
          body: JSON.stringify(_body),
          headers: {
            "api-token": api_token,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log("data--", data.data);
      if(item.inputtype == "Dropdown"){
        const updatedItem = { ...item, inputdata: data.data };
        return updatedItem
      }
      const date = moment(data.data[0].date)
      const updatedItem = {...item, default_start_dt: date.format("YYYY-MM-DD")}
      return updatedItem;
      //return data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }

  setDynamicDropdown = async (event) => {
    const matchedConfigs = this.state.report_config[0].report_config.filter(
      (item) => item.parent_inputkey && item.parent_inputkey === event.target.name
    );
    if(matchedConfigs && matchedConfigs.length > 0){
      const { api_token } =
      (this.props.location && this.props.location.state) || {};
      const fetchId = event.target.value.split("~")[0]
      console.log("dynamicfetchId-->",fetchId)
      matchedConfigs.forEach(async (item) => {
        try{
          let _body = {
            keyname: item.inputkey,
            params: [fetchId],
          };
          const response = await customFetch(
            process.env.REACT_APP_URL + "/reports/" + this.state.report_list,
            {
              method: "POST",
              body: JSON.stringify(_body),
              headers: {
                "api-token": api_token,
                "Content-Type": "application/json",
              },
            }
          );
  
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
  
          const data = await response.json();
          console.log("dynamicJSON-->",data)
          if (data.status == 1) {
            this.state.report_config[0].report_config.forEach((config) => {
              config.inputkey === item.inputkey && (config.inputdata = data.data.map(({status, ...rest}) => rest))
            })
            this.setState({
              dynamicDropdownData: true
            }, () => console.log("showDynamicDropdown",this.state.dynamicDropdownData));
          }
          // const updatedItem = { ...item, inputdata: data.data };
          // return updatedItem;
          //return data.data;
        } catch (error) {
          console.error("Error fetching data:", error);
          return [];
        }
      })
    }
  }

  // Ankur Makavana | 18-07-2023 | store user request
  generate_report = (e) => {
    let matchedConfigs;
    let checkinput = false;
    if (this.state.lender.length == 1) {
      matchedConfigs = this.state.report_config[0].report_config.filter(
        // (item) => item.parent_id === this.state.dropdownVal.split("~")[0]
        (item) => item.inputtype == "calendar_daterange" || item.inputtype == "calendar_yearmonthrange"
      );
      console.log("matchedConfigs", matchedConfigs);
    } else if (this.state.lender.length > 1) {
      matchedConfigs = this.state.report_config[0].report_config.filter(
        (item) => item.parent_id === this.state.dropdownVal.split("~")[0]
        // (item) => item.inputtype == "calendar_daterange" || item.inputtype == "calendar_yearmonthrange"
      );
      console.log("matchedConfigs", matchedConfigs);
    }
    // else {
    //   if (this.state.report_config) {
    //     matchedConfigs = this.state.report_config[0].report_config;
    //   }
    // }
    if (matchedConfigs) {
      checkinput = matchedConfigs.some(
        (item) =>
          item.inputtype == "calendar_daterange" ||
          item.inputtype == "calendar_yearmonthrange"
      );
      console.log("checkinput_if", checkinput);
    }
    let isValidrange = true;
    if (checkinput) {
      let startdateJSON = this.state.lender.find(
        (item) => item.input_name === "calendar_date_start"
      );
      let enddateJSON = this.state.lender.find(
        (item) => item.input_name === "calendar_date_end"
      );
      const startdate = moment(startdateJSON.lender_name)
      const enddate = moment(enddateJSON.lender_name)
      if (matchedConfigs[0].inputtype == "calendar_daterange") {
        const dayDifference = enddate.diff(startdate, "days")
        isValidrange = dayDifference > parseInt(matchedConfigs[0].gap) ? false : true;
      }
      if (matchedConfigs[0].inputtype == "calendar_yearmonthrange") {
        const dayDifference = enddate.diff(startdate, "months")
        isValidrange = dayDifference > parseInt(matchedConfigs[0].gap) ? false : true;
      }
    }
    //e.preventdefault();
    if (isValidrange) {
      console.log(this.state.report_list);
      const { api_token, subcategories_id } =
        (this.props.location && this.props.location.state) || {};
      this.setState({ isSpin: true });
      const uid = localStorage.getItem("in_userid");
      const formdata = new FormData();
      formdata.append("userid", uid);
      formdata.append("report_id", this.state.report_list);
      if (this.state.report_list == 1) {
        let splitdata = this.state.month.split("~");
        let _body = JSON.stringify(
          [{ id: splitdata[0], month: splitdata[1] }],
          null
        );
        formdata.append("user_parameter", _body);
      } else {
        if (this.state.lender.length > 0) {
          let updatedLenderArray = this.state.lender.filter((item) => !item.is_req)
          updatedLenderArray.sort((a, b) => a.mapId - b.mapId)
          updatedLenderArray = updatedLenderArray.map((item) => {
            const { key, input_name, mapId, ...rest } = item;
            return rest;
          });
          formdata.append("user_parameter", [
            JSON.stringify(updatedLenderArray),
          ]);
        } else {
          let nadata = [{}];
          nadata["nakey"] = "NA";
          formdata.append("user_parameter", JSON.stringify(nadata, null));
        }
        console.log(this.state.lender);
        // console.log("ndasd");
        // return;
      }
      customFetch(
        process.env.REACT_APP_URL + "/store/desktop/report/request/detail",
        {
          method: "POST",
          headers: { "api-token": api_token },
          body: formdata,
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (
            data.api_code === 4 ||
            data.api_code === 3 ||
            data.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isErrOpen: true,
            isSpin: false,
            api_error_message: data.Message,
          });
          this.get_report_data();
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    } else {
      this.setState({
        isErrOpen: true,
        api_error_message: matchedConfigs[0].error_msg,
      });
    }
  };
  // Ankur Makavana | 18-07-2023 | download generated report
  downloadReport(url) {
    this.setState({
      isSpin: true,
    });
    //const url = path;
    let filename = url.split("/").pop();
    customFetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        this.setState({
          isSpin: false,
        });
        if (blob) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); // Replace with the desired file name
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }
  async downloadReportUpdateDate(request_no, download_link = ""){
    console.log("reqNo-->", request_no)
    const reqId = request_no.split("/").at(-1)
    console.log("reqId-->", reqId)
    try{
      const { api_token } = (this.props.location && this.props.location.state) || {};
      const response = await customFetch(
        process.env.REACT_APP_URL + "/fetch/report/datetime?request_id=" + reqId,
        {
          method: "POST",
          headers: {
            "api-token": api_token,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      window.location.href=download_link
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  get_hours(create_at) {
    const dateOneObj = new Date(create_at);
    const dateTwoObj = new Date();
    const milliseconds = Math.abs(dateTwoObj - dateOneObj);
    const hours = milliseconds / 36e5;
    return hours;
  }
  get_inputdata(inputdata) {
    let x = inputdata;
    if (Array.isArray(x)) {
      let result = "";

      const filteredData = x.filter((item) => item.hasOwnProperty("parent_id"));
      console.log("report_config--", filteredData);

      if (filteredData.length > 0) {
        let newConfig = []
        this.state.report_config.forEach((reportConfig) => {
          newConfig = reportConfig.report_config.filter((config) => x.some((item) => item.parent_id == config.parent_id))
        })

        x.forEach((item) => {
          if (item.hasOwnProperty("lender_name")) {
            var date = moment(item.lender_name);
            console.log("date-->",date, date.isValid())
            const hasDateType = newConfig.some((dateConfig) => {
              return dateConfig.inputtype === "calendar_daterange" || dateConfig.inputtype === "calendar_date" 
                || dateConfig.inputtype === "calendar_yearmonth" || dateConfig.inputtype === "calendar_yearmonthrange"
            })
            if (date.isValid() && hasDateType) {
              console.log(date.format("DD, MMM, YYYY"))
              newConfig.forEach((config) => {
                if (
                  config.inputtype === "calendar_daterange" ||
                  config.inputtype === "calendar_date"
                ) {
                  result +=
                    moment(item.lender_name).format("YYYY-MM-DD") + " | ";
                } else if (
                  config.inputtype === "calendar_yearmonth" ||
                  config.inputtype === "calendar_yearmonthrange"
                ) {
                  result +=
                    moment(item.lender_name).format("MMM-YYYY") + " | ";
                }
              });
            } else {
              result += item.lender_name + " | "; // Concatenate 'lender_name'
            }
          }
          // Check if 'month_name' exists in the object
          if (item.hasOwnProperty("month_name")) {
            result += item.month_name; // Concatenate 'month_name'
          }
        });
      } else {
        x.forEach((item) => {
          if (this.state.report_list == 1) {
            if (item.hasOwnProperty("month")) {
              result += item.month; // Concatenate 'month_name'
            }
          } else {
            if (item.hasOwnProperty("lender_name")) {
              var date = moment(item.lender_name);
              if (date.isValid()) {
                this.state.report_config.forEach((report_configitem) => {
                  report_configitem.report_config.forEach((config) => {
                    if (
                      config.inputtype === "calendar_daterange" ||
                      config.inputtype === "calendar_date"
                    ) {
                      result +=
                        moment(item.lender_name).format("YYYY-MM-DD") + " | ";
                    } else if (
                      config.inputtype === "calendar_yearmonth" ||
                      config.inputtype === "calendar_yearmonthrange"
                    ) {
                      result +=
                        moment(item.lender_name).format("MMM-YYYY") + " | ";
                    }
                  });
                });
              } else {
                result += item.lender_name + " | "; // Concatenate 'lender_name'
              }
            }
            // Check if 'month_name' exists in the object
            if (item.hasOwnProperty("month_name")) {
              result += item.month_name; // Concatenate 'month_name'
            }
          }
        });
      }
      return result
        .trim()
        .replace(/\|([^|]*)$/, "$1")
        .trim();
    } else {
      const arr = Object.keys(x).map((key) => ({ [key]: x[key] }));
      console.log("arr", arr);
      return arr.map((arrdata) => {
        if (arrdata.month) {
          return arrdata.month;
        } else if (arrdata.lender_name) {
          return arrdata.lender_name;
        }
      });
    }
  }
  async verifyinput(event, config) {
    if(event.target.value && event.target.value !== ''){
      const { api_token } =
        (this.props.location && this.props.location.state) || {};
      try {
        let _body = {
          keyname: event.target.name,
          params: [event.target.value],
        };
        const response = await customFetch(
          process.env.REACT_APP_URL + "/reports/" + this.state.report_list,
          {
            method: "POST",
            body: JSON.stringify(_body),
            headers: {
              "api-token": api_token,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
  
        const data = await response.json();
        this.setState({ isValidData: data.status });
        if (data.status == 1) {
          var lender_data = {
            key: config.inputkey,
            id: event.target.value,
            lender_name: event.target.value,
            input_name: event.target.name,
            parent_id: config.parent_id ? config.parent_id : 0,
            mapId: config.mapId
          };
          //this.state.lender.push(lender_data);
      
          // Check if input_name already exists
          const index = this.state.lender.findIndex(
            (item) => item.input_name === lender_data.input_name
          );
      
          if (index !== -1) {
            // If input_name already exists, update the existing lender data
            this.setState(
              (prevState) => ({
                lender: prevState.lender.map((item, idx) =>
                  idx === index ? { ...item, ...lender_data } : item
                ),
              }),
              () => this.filterlenderdata()
            );
          } else {
            // If input_name does not exist, add the new lender data to the state
            this.setState(
              (prevState) => ({
                lender: [...prevState.lender, lender_data],
              }),
              () => this.filterlenderdata()
            );
          }
          console.log("inputdata--", data.status);
          this.setState({
            loadButtonFlag: 1,
          });
        }
        // const updatedItem = { ...item, inputdata: data.data };
        // return updatedItem;
        //return data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    }
    else{
      const filteredLender = this.state.lender.filter(item => item.key !== config.inputkey)
      this.setState({
        lender: filteredLender,
        loadButtonFlag: 0
      })
    }
  }
  setOption(event, config, prevParentId = null) {
    if(!event.target.value){
      const matchedConfigs = this.state.lender.filter(
        (item) => item.parent_inputkey && item.parent_inputkey === event.target.name
      );
      const filteredConfig = this.state.lender.filter((item) => {
        const containsParentKeyConfig = matchedConfigs.some((config) => item.parent_inputkey && config.parent_inputkey === item.parent_inputkey)
        if(containsParentKeyConfig){
          this.setState({
            dynamicDropdownData: false
          })
          return false
        }
        if(config.mapId !== item.mapId){
          return prevParentId === item.parent_id ? false : true
        }
        else if(prevParentId && item.parent_id && prevParentId !== item.parent_id){
          return true
        }
        return false
      })
      this.setState({
        lender: filteredConfig
      })
    }
    else{
      var tmp = event.target.value.split("~");
      var lender_data = {
        key: config.inputkey,
        id: tmp[0],
        lender_name: tmp[1],
        input_name: event.target.name,
        mapId: config.mapId,
      };
      if(config.parent_id){
        lender_data.parent_id = config.parent_id
      }
      if(config.is_req){
        lender_data.is_req = config.is_req
      }
      if(config.parent_inputkey){
        lender_data.parent_inputkey = config.parent_inputkey
      }
      //this.state.lender.push(lender_data);
  
      // Check if input_name already exists
      const index = this.state.lender.findIndex(
        (item) => item.input_name === lender_data.input_name
      );
  
      if (index !== -1) {
        // If input_name already exists, update the existing lender data
        this.setState(
          (prevState) => ({
            lender: prevState.lender.map((item, idx) =>
              idx === index ? { ...item, ...lender_data } : item
            ),
          }),
          () => {
            this.filterlenderdata();
            this.filterLenderOnParentKey(event)
          }
        );
      } else {
        // If input_name does not exist, add the new lender data to the state
        this.setState(
          (prevState) => ({
            lender: [...prevState.lender, lender_data],
          }),
          () => {
            this.filterlenderdata();
            this.filterLenderOnParentKey(event)
          }
        );
      }
      
      this.state.loadButtonFlag = 1;
      this.forceUpdate();
    }
  }

  filterLenderOnParentKey(event){
    const newLender = this.state.lender.filter((item) => {
      if(item.parent_inputkey){
        return item.parent_inputkey !== event.target.name
      }
      return true
    });
    this.setState({
      lender: newLender
    })
  }

  filterlenderdata() {
    let hasParentWithValue = false;

    this.state.lender.forEach((item) => {
      if (item.hasOwnProperty("parent_id")) {
        if (item.parent_id !== null && item.parent_id !== undefined) {
          hasParentWithValue = true;
        }
      }
    });
    if (hasParentWithValue) {
      const filteredData = this.state.lender.filter(
        (item) =>
          item.id == this.state.dropdownVal.split("~")[0] ||
          item.parent_id == this.state.dropdownVal.split("~")[0]
      );
      if (filteredData.length > 0) {
        this.setState({
          lender: filteredData,
        });
      }
    }
  }
  loadreport(e) {
    e && e.preventDefault();
    const { api_token, effective_designation_id } = this.props || {};
    this.setState({ pin: true });
    const empid = localStorage.getItem("employee_id");
    let parameter_ary = [];
    let flag = 0;
    this.state.report_config.map((configdata) =>
      configdata.report_config.length > 1
        ? this.state.period_val.length !== configdata.report_config.length
          ? (flag = 1)
          : (parameter_ary = this.state.period_val)
        : // configdata.report_config.map((item) => {
          //   parameter_ary.push({
          //     [item.inputkey]: this.state[
          //       item.inputkey
          //     ],
          //   })

          // })
          parameter_ary.push({
            [configdata.report_config[0].inputkey]: this.state[
              configdata.report_config[0].inputkey
            ],
          })
    );

    console.log(parameter_ary);
    if (flag) {
      alert("PLease Select All Optons!");
      this.setState({ pin: false });
      return false;
    }
    const formdata = new FormData();
    formdata.append("report_id", this.state.report_list);
    formdata.append("employee_id", empid);
    formdata.append("parameter", JSON.stringify(parameter_ary));
    // console.log(this.state.report_list);
    // console.log(empid);
    // console.log(JSON.stringify(parameter_ary));
    // return false;
    customFetch(process.env.REACT_APP_URL + "/set/procedure", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        // this.setState({
        //   isSpin: false,
        // });
        console.log(data);
        if (data[0].status == 1) {
          this.get_reportlink();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getInputLength(data) {
    const filteredData = this.state.lender.filter(
      (item) =>
        item.id == this.state.dropdownVal.split("~")[0] ||
        item.parent_id == this.state.dropdownVal.split("~")[0]
    );
    // this.setState({
    //   lender: filteredData,
    // });
    let newConfig = data.filter((config) => this.state.lender.some((item) => item.parent_id == config.parent_id))

    console.log("data--", data);

    let hasParentWithValue = false;

    data.forEach((item) => {
      if (item.hasOwnProperty("parent_id")) {
        if (item.parent_id !== null && item.parent_id !== undefined) {
          hasParentWithValue = true;
        }
      }
    });

    const matchedConfigs = data.filter(
      (item) => item.parent_id === this.state.dropdownVal.split("~")[0]
    );

    console.log("matchedConfigs", matchedConfigs);
    let checkinput = false;
    if (matchedConfigs.length > 0 && hasParentWithValue) {
      checkinput = matchedConfigs.some(
        (item) =>
          item.inputtype == "calendar_daterange" ||
          item.inputtype == "calendar_yearmonthrange"
      );
      console.log("checkinput_if", checkinput);
    } else {
      console.log("checkinput_else", checkinput);
      checkinput = data.some(
        (item) =>
          item.inputtype == "calendar_daterange" ||
          item.inputtype == "calendar_yearmonthrange"
      );
    }
    if (checkinput) {
      //return data.length + 1;
      console.log("lengthdata");
      if (!hasParentWithValue) {
        if (data.length == 1) {
          console.log(
            "nothasParentWithValue_data1",
            data.length + "==" + (this.state.lender.length + 1)
          );
          return data.length == this.state.lender.length - 1;
        } else {
          console.log(
            "nothasParentWithValue_data+1",
            data.length + "==" + (this.state.lender.length - 1)
          );
          return data.length == this.state.lender.length - 1;
        }
      } else if (filteredData.length > 1) {
        return newConfig.length == this.state.lender.length - 1;
      }
    }
    if (hasParentWithValue) {
      if (filteredData.length > 1) {
        console.log(
          "hasParentWithValue_else",
          this.state.lender.length + "==" + newConfig.length
        );
        return newConfig.length == this.state.lender.length;
      }
    } else {
      console.log(
        "lengthdata_else",
        data.length + "-" + this.state.lender.length + "-" + filteredData.length
      );
      return data.length == this.state.lender.length;
    }
  }

  render() {
    const { api_token, subcategories_name } =
      (this.props.location && this.props.location.state) || {};
    const {
      sub_categories_data,
      demand_sheet,
      reportList_data,
      isErrOpen,
      api_error_message,
      report_data,
    } = this.state;
    console.log(
      "report=>",
      this.state.report_config && this.state.report_config
    );
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div className="container no-container">
          <div className="top_nav_report">
            {/* <a>
              <div
                className="icon_bg-report"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/dashboard",
                    state: {
                      api_token: api_token,
                    },
                  });
                }}
              >
                <img
                  src={require("../assets/images/home_white_logo.png")}
                  className="icon_img"
                />
              </div>
            </a> */}
            <a>
              <div
                className="icon_bg-report"
                onClick={() => this.props.history.goBack()}
              >
                {/* AT-2335 ||Rohit Chitampalle ||23-08-2023 ||change in icon */}
                <img
                  src={require("../assets/images/back-white.png")}
                  className="icon_img"
                />
              </div>
            </a>
            <span className="head-title">{subcategories_name}</span>

            <a>
            {this.state.flag === 0 && (
              <div className="log-out-reporthead">
                <img
                  src={require("../assets/images/power-back.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
              )}
            </a>
          </div>
          <div className="mt-12">
            <div className="row">
              {reportList_data && reportList_data.length > 0 ? (
                <div className="col-md-4">
                  <span className="f18">Select Report</span>
                  {/*<select
                    className="form-control"
                    name="report_list"
                    onChange={(e) => this.get_reportconfig(e.target.value)}
                  >
                    <option value="">-</option>
                    {reportList_data &&
                      reportList_data.map((reportList, index) => (
                        <option value={reportList.report_id} key={index}>
                          {reportList.report_name}
                        </option>
                      ))}
                      </select>*/}
                  <PESelect element_id={"SelectReport"}
                    showSearch
                    //className="form-control"
                    onChange={(e) => this.get_reportconfig(e)}
                    //onChange={(e) => console.log("E-->", e)}
                    placeholder="-"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option && option.label
                        ? option.label.toLowerCase()
                        : ""
                      ).includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA && optionA.label
                        ? optionA.label.toLowerCase()
                        : ""
                      ).localeCompare(
                        optionB && optionB.label
                          ? optionB.label.toLowerCase()
                          : ""
                      )
                    }
                    options={
                      reportList_data &&
                      reportList_data.map((item) => {
                        return {
                          label: item.report_name,
                          value: item.report_id,
                        };
                      })
                    }
                  />
                </div>
              ) : (
                <div className="text-center">No Data Found</div>
              )}

              {this.state.report_list == 4 && (
                <Cash_balance_report api_token={api_token} />
              )}
              {this.state.report_list == 1 ? (
                <>
                  <div className="col-md-4">
                    <span className="f18">Select Month</span>
                    <select
                      className="form-control"
                      name="month"
                      onChange={(e) => this.setState({ month: e.target.value })}
                    >
                      <option value="">-</option>
                      {demand_sheet &&
                        demand_sheet.map((reportList, index) => (
                          <option
                            key={index}
                            value={reportList.id + "~" + reportList.month}
                          >
                            {reportList.month}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <span className="f18">&nbsp;</span>
                    <button
                      disabled={
                        !this.state.month || this.state.processed_AtCount > 0
                      }
                      className={
                        !this.state.month || this.state.processed_AtCount > 0
                          ? "form-control btn-primary p-2 btn-height disablesendreqbtn"
                          : "form-control btn-primary p-2 btn-height"
                      }
                      onClick={this.generate_report}
                    >
                      Send Request
                    </button>
                  </div>
                </>
              ) : (
                this.state.report_config.map((configdata) =>
                  configdata.report_config.length > 1 ? (
                    configdata.report_config.map((report_config) => (
                      <>
                        {console.log(
                          "report_config.inputlabel",
                          report_config.inputtype
                        )}
                        {report_config.inputtype === "Dropdown" &&
                        (report_config.parent_id
                          ? this.state.dropdownVal.split("~")[0] ==
                            report_config.parent_id
                          : true) && !report_config.parent_inputkey ? (
                          <div className="col-md-4">
                            {report_config.inputdata.map((item) => (console.log("item-->", item)))}
                            <span>{report_config.inputlabel}</span>
                            <select
                              className="form-control text-center"
                              name={report_config.inputkey}
                              value={this.state[report_config.inputkey]}
                              onChange={(e) => {
                                if(e.target.value && e.target.value !== ""){
                                  if(!report_config.parent_id){
                                    this.setState({
                                      dropdownVal: e.target.value,
                                    })
                                  }
                                  this.setOption(e, report_config);
                                  this.setDynamicDropdown(e)
                                } else{
                                  const prevDropdownVal = this.state.dropdownVal
                                  const prevParentId = prevDropdownVal.split("~")[0]
                                  this.setState({
                                    loadButtonFlag: 0,
                                    dropdownVal: prevParentId === report_config.parent_id ? prevDropdownVal : ""
                                  }, () => {
                                    if(prevParentId === report_config.parent_id){
                                      this.setOption(e, report_config)
                                    }
                                    else{
                                      this.setOption(e, report_config, prevParentId)
                                    }
                                  })
                                }
                              }}
                            >
                              <option value="">-</option>
                              {report_config.inputdata.map((item) => (
                                <option value={item.id + "~" + item.datakey}>
                                  {item.datakey}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : report_config.inputtype === "Dropdown" &&
                          (report_config.parent_inputkey
                            ? this.state.dropdownVal.split("~")[1] ==
                              report_config.parent_inputkey
                            : false) ? (
                              <>
                                {this.state.dynamicDropdownData && <div className="col-md-4">
                                  {report_config.inputdata.map((item) => (console.log("item-->", item)))}
                                  <span>{report_config.inputlabel}</span>
                                  <select
                                    className="form-control text-center"
                                    name={report_config.inputkey}
                                    // value={this.state[report_config.inputkey]}
                                    value={
                                      (() => {
                                        const item = this.state.lender.find(item => item.input_name === report_config.inputkey)
                                        return item ? `${item.id}~${item.lender_name}` : ''
                                      })()
                                    }
                                    onChange={(e) => {
                                      if(e.target.value && e.target.value !== ""){
                                        if(!report_config.parent_id){
                                          this.setState({
                                            dropdownVal: e.target.value,
                                          })
                                        }
                                        this.setOption(e, report_config);
                                      }
                                      else{
                                        const prevDropdownVal = this.state.dropdownVal
                                        const prevParentId = prevDropdownVal.split("~")[0]
                                        this.setState({
                                          loadButtonFlag: 0,
                                          dropdownVal: prevParentId === report_config.parent_id ? prevDropdownVal : ""
                                        }, () => {
                                          if(prevParentId === report_config.parent_id){
                                            this.setOption(e, report_config)
                                          }
                                          else{
                                            this.setOption(e, report_config, prevParentId)
                                          }
                                        })
                                      }
                                    }}
                                  >
                                    <option value="">-</option>
                                    {report_config.inputdata.map((item) => (
                                      <option value={item.id + "~" + item.datakey}>
                                        {item.datakey}
                                      </option>
                                    ))}
                                  </select>
                                </div>}
                              </>
                        ) : report_config.inputtype === "textbox" &&
                          (report_config.parent_id
                            ? this.state.dropdownVal.split("~")[0] ==
                              report_config.parent_id
                            : true) ? (
                          <div className="col-md-4">
                            <span>{report_config.inputlabel}</span>
                            <input
                              className="form-control"
                              name={report_config.inputkey}
                              value={this.state[report_config.inputkey]}
                              onBlur={(e) =>
                                this.verifyinput(e, report_config)
                              }
                            />
                            {this.state.isValidData == 0 ? (
                              <span className="errmsg">
                                {report_config.error_message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : report_config.inputtype === "calendar_yearmonth" &&
                          (report_config.parent_id
                            ? this.state.dropdownVal.split("~")[0] ==
                              report_config.parent_id
                            : true) ? (
                          <div className="col-md-4">
                            <span>{report_config.inputlabel}</span>
                            <DatePicker
                              format={"MMM-YY"}
                              pickerClass="antd_dateform-control"
                              picker="month"
                              placeholder="Select Month"
                              onChange={(e) => {
                                if(e){
                                  const selectedDate = moment(e).format(
                                    "YYYY-MM"
                                  );
                                  const startDate = `${selectedDate}-01`;
                                  var lender_data = {
                                    key: report_config.inputkey,
                                    id: startDate,
                                    lender_name: startDate,
                                    input_name: "calendar_yearmonth",
                                    parent_id: report_config.parent_id,
                                    mapId: report_config.mapId
                                  };
                                  //this.state.lender.push(lender_data);
  
                                  // Check if input_name already exists
                                  const index = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name === lender_data.input_name
                                  );
  
                                  if (index !== -1) {
                                    // If input_name already exists, update the existing lender data
                                    this.setState(
                                      (prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: prevState.lender.map(
                                          (item, idx) =>
                                            idx === index
                                              ? { ...item, ...lender_data }
                                              : item
                                        ),
                                      }),
                                      () => this.filterlenderdata()
                                    );
                                  } else {
                                    // If input_name does not exist, add the new lender data to the state
                                    this.setState(
                                      (prevState) => ({
                                        lender: [
                                          ...prevState.lender,
                                          lender_data,
                                        ],
                                      }),
                                      () => this.filterlenderdata()
                                    );
                                  }
                                } else {
                                  const filteredConfig = this.state.lender.filter((item) => {
                                    return item.mapId !== report_config.mapId
                                  })
                                  this.state.lender = filteredConfig
                                  this.setState({
                                    loadButtonFlag: 0,
                                    lender: filteredConfig
                                  })
                                }
                              }}
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  (current <
                                    moment(report_config.default_start_dt) ||
                                    current >
                                      moment().add(
                                        report_config.max_month,
                                        "months"
                                      ))
                                );
                              }}
                            />
                          </div>
                        ) : report_config.inputtype ===
                            "calendar_yearmonthrange" &&
                          (report_config.parent_id
                            ? this.state.dropdownVal.split("~")[0] ==
                              report_config.parent_id
                            : true) ? (
                          <div className="col-md-4">
                            <span>{report_config.inputlabel}</span>
                            <RangePicker
                              format={"MMM-YY"}
                              pickerClass="antd_dateform-control"
                              picker="month"
                              placeholder={["Start Month", "End Month"]}
                              onChange={(dates, e) => {
                                if (dates && dates.length === 2) {
                                  const startDate = moment(dates[0])
                                    .startOf("month")
                                    .format("YYYY-MM-DD");
                                  const endDate = moment(dates[1])
                                    .endOf("month")
                                    .format("YYYY-MM-DD");

                                  {
                                    const lender_data_start = {
                                      key: report_config.inputkey,
                                      id: startDate,
                                      lender_name: startDate,
                                      input_name: "calendar_date_start",
                                      parent_id: report_config.parent_id,
                                      mapId: report_config.mapId
                                    };

                                    const lender_data_end = {
                                      key: report_config.inputkey,
                                      id: endDate,
                                      lender_name: endDate,
                                      input_name: "calendar_date_end",
                                      parent_id: report_config.parent_id,
                                      mapId: report_config.mapId + 1
                                    };

                                    // Check if input_name already exists for startDate
                                    const indexStart = this.state.lender.findIndex(
                                      (item) =>
                                        item.input_name ===
                                        lender_data_start.input_name
                                    );

                                    // Check if input_name already exists for endDate
                                    const indexEnd = this.state.lender.findIndex(
                                      (item) =>
                                        item.input_name ===
                                        lender_data_end.input_name
                                    );

                                    // Update startDate if it exists, or add it if it doesn't
                                    if (indexStart !== -1) {
                                      this.setState(
                                        (prevState) => ({
                                          loadButtonFlag: 1,
                                          lender: prevState.lender.map(
                                            (item, idx) =>
                                              idx === indexStart
                                                ? {
                                                    ...item,
                                                    ...lender_data_start,
                                                  }
                                                : item
                                          ),
                                        }),
                                        () => this.filterlenderdata()
                                      );
                                    } else {
                                      this.setState(
                                        (prevState) => ({
                                          loadButtonFlag: 1,
                                          lender: [
                                            ...prevState.lender,
                                            lender_data_start,
                                          ],
                                        }),
                                        () => this.filterlenderdata()
                                      );
                                    }

                                    // Update endDate if it exists, or add it if it doesn't
                                    if (indexEnd !== -1) {
                                      this.setState((prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: prevState.lender.map(
                                          (item, idx) =>
                                            idx === indexEnd
                                              ? { ...item, ...lender_data_end }
                                              : item
                                        ),
                                      }));
                                    } else {
                                      this.setState((prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: [
                                          ...prevState.lender,
                                          lender_data_end,
                                        ],
                                      }));
                                    }
                                  }
                                } else {
                                  const filteredConfig = this.state.lender.filter((item) => {
                                    return item.mapId !== report_config.mapId 
                                    && item.mapId !== (report_config.mapId + 1)
                                  })
                                  this.state.lender = filteredConfig
                                  this.setState({
                                    loadButtonFlag: 0,
                                    lender: filteredConfig
                                  })
                                }
                              }}
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  (current <
                                    moment(report_config.default_start_dt) ||
                                    current >
                                      moment().add(
                                        report_config.to_max_month,
                                        "months"
                                      ))
                                );
                              }}
                            />
                          </div>
                        ) : report_config.inputtype === "calendar_date" &&
                          (report_config.parent_id
                            ? this.state.dropdownVal.split("~")[0] ==
                              report_config.parent_id
                            : true) ? (
                          <div className="col-md-4">
                            <span>{report_config.inputlabel}</span>
                            <DatePicker
                              format={"YYYY-MM-DD"}
                              pickerClass="antd_dateform-control"
                              placeholder="Select Month"
                              onChange={(e) => {
                                if(e){
                                  const startDate = moment(e).format(
                                    "YYYY-MM-DD"
                                  );
                                  //const startDate = `${selectedDate}-01`;
                                  var lender_data = {
                                    key: report_config.inputkey,
                                    id: startDate,
                                    lender_name: startDate,
                                    input_name: "calendar_date",
                                    parent_id: report_config.parent_id,
                                    mapId: report_config.mapId
                                  };
                                  //this.state.lender.push(lender_data);
  
                                  // Check if input_name already exists
                                  const index = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name === lender_data.input_name
                                  );
  
                                  if (index !== -1) {
                                    // If input_name already exists, update the existing lender data
                                    this.setState(
                                      (prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: prevState.lender.map(
                                          (item, idx) =>
                                            idx === index
                                              ? { ...item, ...lender_data }
                                              : item
                                        ),
                                      }),
                                      () => this.filterlenderdata()
                                    );
                                  } else {
                                    // If input_name does not exist, add the new lender data to the state
                                    this.setState(
                                      (prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: [
                                          ...prevState.lender,
                                          lender_data,
                                        ],
                                      }),
                                      () => this.filterlenderdata()
                                    );
                                  }
                                } else {
                                  const filteredConfig = this.state.lender.filter((item) => {
                                    return item.mapId !== report_config.mapId
                                  })
                                  this.state.lender = filteredConfig
                                  this.setState({
                                    loadButtonFlag: 0,
                                    lender: filteredConfig
                                  })
                                }
                              }}
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  (current <
                                    moment(report_config.default_start_dt) ||
                                    current >
                                      moment().add(
                                        report_config.max_month,
                                        "months"
                                      ))
                                );
                              }}
                            />
                          </div>
                        ) : report_config.inputtype === "calendar_daterange" &&
                          (report_config.parent_id
                            ? this.state.dropdownVal.split("~")[0] ==
                              report_config.parent_id
                            : true) ? (
                          <div className="col-md-4">
                            <span>{report_config.inputlabel}</span>
                            <RangePicker
                              format={"YYYY-MM-DD"}
                              pickerClass="antd_dateform-control"
                              placeholder={["Start Date", "End Date"]}
                              onChange={(dates, e) => {
                                if (dates && dates.length === 2) {
                                  const startDate = moment(dates[0]).format(
                                    "YYYY-MM-DD"
                                  );
                                  const endDate = moment(dates[1]).format(
                                    "YYYY-MM-DD"
                                  );

                                  {
                                    const lender_data_start = {
                                      key: report_config.inputkey,
                                      id: startDate,
                                      lender_name: startDate,
                                      input_name: "calendar_date_start",
                                      parent_id: report_config.parent_id,
                                      mapId: report_config.mapId
                                    };

                                    const lender_data_end = {
                                      key: report_config.inputkey,
                                      id: endDate,
                                      lender_name: endDate,
                                      input_name: "calendar_date_end",
                                      parent_id: report_config.parent_id,
                                      mapId: report_config.mapId + 1
                                    };

                                    // Check if input_name already exists for startDate
                                    const indexStart = this.state.lender.findIndex(
                                      (item) =>
                                        item.input_name ===
                                        lender_data_start.input_name
                                    );

                                    // Check if input_name already exists for endDate
                                    const indexEnd = this.state.lender.findIndex(
                                      (item) =>
                                        item.input_name ===
                                        lender_data_end.input_name
                                    );

                                    // Update startDate if it exists, or add it if it doesn't
                                    if (indexStart !== -1) {
                                      this.setState(
                                        (prevState) => ({
                                          loadButtonFlag: 1,
                                          lender: prevState.lender.map(
                                            (item, idx) =>
                                              idx === indexStart
                                                ? {
                                                    ...item,
                                                    ...lender_data_start,
                                                  }
                                                : item
                                          ),
                                        }),
                                        () => this.filterlenderdata()
                                      );
                                    } else {
                                      this.setState(
                                        (prevState) => ({
                                          loadButtonFlag: 1,
                                          lender: [
                                            ...prevState.lender,
                                            lender_data_start,
                                          ],
                                        }),
                                        () => this.filterlenderdata()
                                      );
                                    }

                                    // Update endDate if it exists, or add it if it doesn't
                                    if (indexEnd !== -1) {
                                      this.setState((prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: prevState.lender.map(
                                          (item, idx) =>
                                            idx === indexEnd
                                              ? { ...item, ...lender_data_end }
                                              : item
                                        ),
                                      }));
                                    } else {
                                      this.setState((prevState) => ({
                                        loadButtonFlag: 1,
                                        lender: [
                                          ...prevState.lender,
                                          lender_data_end,
                                        ],
                                      }));
                                    }
                                  }
                                } else {
                                  const filteredConfig = this.state.lender.filter((item) => {
                                    return item.mapId !== report_config.mapId 
                                    && item.mapId !== (report_config.mapId + 1)
                                  })
                                  this.state.lender = filteredConfig
                                  this.setState({
                                    loadButtonFlag: 0,
                                    lender: filteredConfig
                                  })
                                }
                              }}
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  (current <
                                    moment(report_config.default_start_dt) ||
                                    current >
                                      moment().add(
                                        "days",
                                        report_config.to_max_days
                                      ))
                                );
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))
                  ) : (
                    <div className="col-md-4">
                      {console.log("inputtype", configdata)}
                      {configdata.report_config[0].inputtype == "Dropdown" ? (
                        <>
                          <span>{configdata.report_config[0].inputlabel}</span>
                          <select
                            className="form-control text-center"
                            name={configdata.report_config[0].inputkey}
                            value={
                              this.state[configdata.report_config[0].inputkey]
                            }
                            onChange={(e) => this.setOption(e, configdata.report_config[0])}
                          >
                            <option value="">-</option>
                            {configdata.report_config[0].inputdata.map(
                              (item) => (
                                <option value={item.id + "~" + item.datakey}>
                                  {item.datakey}
                                </option>
                              )
                            )}
                          </select>
                        </>
                      ) : configdata.report_config[0].inputtype ==
                        "calendar_yearmonth" ? (
                        <>
                          <span>{configdata.report_config[0].inputlabel}</span>
                          <DatePicker
                            format={"MMM-YY"}
                            pickerClass="antd_dateform-control"
                            picker="month"
                            placeholder="Select Month"
                            onChange={(e) =>
                              // this.setState((prevState) => {
                              //   const selectedDate = moment(e).format(
                              //     "YYYY-MM"
                              //   );
                              //   const startDate = `${selectedDate}-01`;

                              //   return {
                              //     [configdata.report_config[0]
                              //       .inputkey]: startDate,
                              //     lender: [
                              //       ...prevState.lender,
                              //       { id: startDate, lender_name: startDate },
                              //     ],
                              //     loadButtonFlag: 1,
                              //   };
                              // })
                              {
                                if(e){
                                  const selectedDate = moment(e).format(
                                    "YYYY-MM"
                                  );
                                  const startDate = `${selectedDate}-01`;
                                  var lender_data = {
                                    key: configdata.report_config[0].inputkey,
                                    id: startDate,
                                    lender_name: startDate,
                                    input_name: "calendar_yearmonth",
                                    mapId: configdata.report_config[0].mapId
                                  };
                                  //this.state.lender.push(lender_data);
  
                                  // Check if input_name already exists
                                  const index = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name === lender_data.input_name
                                  );
  
                                  if (index !== -1) {
                                    // If input_name already exists, update the existing lender data
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: prevState.lender.map((item, idx) =>
                                        idx === index
                                          ? { ...item, ...lender_data }
                                          : item
                                      ),
                                    }));
                                  } else {
                                    // If input_name does not exist, add the new lender data to the state
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: [...prevState.lender, lender_data],
                                    }));
                                  }
                                } else {
                                  const filteredConfig = this.state.lender.filter((item) => {
                                    return item.mapId !== configdata.report_config[0].mapId
                                  })
                                  this.state.lender = filteredConfig
                                  this.setState({
                                    loadButtonFlag: 0,
                                    lender: filteredConfig
                                  })
                                }
                              }
                            }
                            disabledDate={(current) => {
                              return (
                                current &&
                                (current <
                                  moment(
                                    configdata.report_config[0].default_start_dt
                                  ) ||
                                  current >
                                    moment().add(
                                      configdata.report_config[0].max_month,
                                      "months"
                                    ))
                              );
                            }}
                          />
                        </>
                      ) : configdata.report_config[0].inputtype ==
                        "calendar_yearmonthrange" ? (
                        <>
                          <span>{configdata.report_config[0].inputlabel}</span>
                          <RangePicker
                            format={"MMM-YY"}
                            pickerClass="antd_dateform-control"
                            picker="month"
                            placeholder={["Start Month", "End Month"]}
                            onChange={(dates, e) => {
                              if (dates && dates.length === 2) {
                                const startDate = moment(dates[0])
                                  .startOf("month")
                                  .format("YYYY-MM-DD");
                                const endDate = moment(dates[1])
                                  .endOf("month")
                                  .format("YYYY-MM-DD");

                                {
                                  const lender_data_start = {
                                    key: configdata.report_config[0].inputkey,
                                    id: startDate,
                                    lender_name: startDate,
                                    input_name: "calendar_date_start",
                                    mapId: configdata.report_config[0].mapId
                                  };

                                  const lender_data_end = {
                                    key: configdata.report_config[0].inputkey,
                                    id: endDate,
                                    lender_name: endDate,
                                    input_name: "calendar_date_end",
                                    mapId: configdata.report_config[0].mapId + 1
                                  };

                                  // Check if input_name already exists for startDate
                                  const indexStart = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name ===
                                      lender_data_start.input_name
                                  );

                                  // Check if input_name already exists for endDate
                                  const indexEnd = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name ===
                                      lender_data_end.input_name
                                  );

                                  // Update startDate if it exists, or add it if it doesn't
                                  if (indexStart !== -1) {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: prevState.lender.map(
                                        (item, idx) =>
                                          idx === indexStart
                                            ? {
                                                ...item,
                                                ...lender_data_start,
                                              }
                                            : item
                                      ),
                                    }));
                                  } else {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: [
                                        ...prevState.lender,
                                        lender_data_start,
                                      ],
                                    }));
                                  }

                                  // Update endDate if it exists, or add it if it doesn't
                                  if (indexEnd !== -1) {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: prevState.lender.map(
                                        (item, idx) =>
                                          idx === indexEnd
                                            ? { ...item, ...lender_data_end }
                                            : item
                                      ),
                                    }));
                                  } else {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: [
                                        ...prevState.lender,
                                        lender_data_end,
                                      ],
                                    }));
                                  }
                                }

                                // this.setState((prevState) => ({
                                //   [configdata.report_config[0]
                                //     .inputkey]: `${startDate}-${endDate}`,
                                //   lender: [
                                //     ...prevState.lender,
                                //     { id: startDate, lender_name: startDate },
                                //     { id: endDate, lender_name: endDate },
                                //   ],
                                //   loadButtonFlag: 1,
                                // }));
                              } else {
                                const filteredConfig = this.state.lender.filter((item) => {
                                  return item.mapId !== configdata.report_config[0].mapId 
                                  && item.mapId !== (configdata.report_config[0].mapId + 1)
                                })
                                this.state.lender = filteredConfig
                                this.setState({
                                  loadButtonFlag: 0,
                                  lender: filteredConfig
                                })
                              }
                            }}
                            disabledDate={(current) => {
                              return (
                                current &&
                                (current <
                                  moment(
                                    configdata.report_config[0].default_start_dt
                                  ) ||
                                  current >
                                    moment().add(
                                      configdata.report_config[0].to_max_month,
                                      "months"
                                    ))
                              );
                            }}
                          />
                        </>
                      ) : configdata.report_config[0].inputtype ==
                        "calendar_daterange" ? (
                        <>
                          <span>{configdata.report_config[0].inputlabel}</span>
                          <RangePicker
                            format={"YYYY-MM-DD"}
                            pickerClass="antd_dateform-control"
                            placeholder={["Start Date", "End Date"]}
                            onChange={(dates, e) => {
                              if (dates && dates.length === 2) {
                                const startDate = moment(dates[0]).format(
                                  "YYYY-MM-DD"
                                );
                                const endDate = moment(dates[1]).format(
                                  "YYYY-MM-DD"
                                );

                                {
                                  const lender_data_start = {
                                    key: configdata.report_config[0].inputkey,
                                    id: startDate,
                                    lender_name: startDate,
                                    input_name: "calendar_date_start",
                                    mapId: configdata.report_config[0].mapId
                                  };

                                  const lender_data_end = {
                                    key: configdata.report_config[0].inputkey,
                                    id: endDate,
                                    lender_name: endDate,
                                    input_name: "calendar_date_end",
                                    mapId: configdata.report_config[0].mapId + 1
                                  };

                                  // Check if input_name already exists for startDate
                                  const indexStart = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name ===
                                      lender_data_start.input_name
                                  );

                                  // Check if input_name already exists for endDate
                                  const indexEnd = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name ===
                                      lender_data_end.input_name
                                  );

                                  // Update startDate if it exists, or add it if it doesn't
                                  if (indexStart !== -1) {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: prevState.lender.map(
                                        (item, idx) =>
                                          idx === indexStart
                                            ? {
                                                ...item,
                                                ...lender_data_start,
                                              }
                                            : item
                                      ),
                                    }));
                                  } else {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: [
                                        ...prevState.lender,
                                        lender_data_start,
                                      ],
                                    }));
                                  }

                                  // Update endDate if it exists, or add it if it doesn't
                                  if (indexEnd !== -1) {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: prevState.lender.map(
                                        (item, idx) =>
                                          idx === indexEnd
                                            ? { ...item, ...lender_data_end }
                                            : item
                                      ),
                                    }));
                                  } else {
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: [
                                        ...prevState.lender,
                                        lender_data_end,
                                      ],
                                    }));
                                  }
                                }
                                // this.setState((prevState) => ({
                                //   [configdata.report_config[0]
                                //     .inputkey]: `${startDate}-${endDate}`,
                                //   lender: [
                                //     ...prevState.lender,
                                //     { id: startDate, lender_name: startDate },
                                //     { id: endDate, lender_name: endDate },
                                //   ],
                                //   loadButtonFlag: 1,
                                // }));
                              } else {
                                const filteredConfig = this.state.lender.filter((item) => {
                                  return item.mapId !== configdata.report_config[0].mapId 
                                  && item.mapId !== (configdata.report_config[0].mapId + 1)
                                })
                                this.state.lender = filteredConfig
                                this.setState({
                                  loadButtonFlag: 0,
                                  lender: filteredConfig
                                })
                              }
                            }}
                            disabledDate={(current) => {
                              return (
                                current &&
                                (current <
                                  moment(
                                    configdata.report_config[0].default_start_dt
                                  ) ||
                                  current >
                                    moment().add(
                                      "days",
                                      configdata.report_config[0].to_max_days
                                    ))
                              );
                            }}
                          />
                        </>
                      ) : configdata.report_config[0].inputtype ==
                        "calendar_date" ? (
                        <>
                          <span>{configdata.report_config[0].inputlabel}</span>
                          <DatePicker
                            format={"YYYY-MM-DD"}
                            pickerClass="antd_dateform-control"
                            placeholder="Select Month"
                            onChange={(e) =>
                              // this.setState((prevState) => {
                              //   const startDate = moment(e).format(
                              //     "YYYY-MM-DD"
                              //   );
                              //   // const startDate = `${selectedDate}-01`;
                              //   return {
                              //     [configdata.report_config[0]
                              //       .inputkey]: startDate,
                              //     lender: [
                              //       ...prevState.lender,
                              //       { id: startDate, lender_name: startDate },
                              //     ],
                              //     loadButtonFlag: 1,
                              //   };
                              // })

                              {
                                if(e){
                                  const startDate = moment(e).format(
                                    "YYYY-MM-DD"
                                  );
                                  //const startDate = `${selectedDate}-01`;
                                  var lender_data = {
                                    key: configdata.report_config[0].inputkey,
                                    id: startDate,
                                    lender_name: startDate,
                                    input_name: "calendar_date",
                                    mapId: configdata.report_config[0].mapId
                                  };
                                  //this.state.lender.push(lender_data);
  
                                  // Check if input_name already exists
                                  const index = this.state.lender.findIndex(
                                    (item) =>
                                      item.input_name === lender_data.input_name
                                  );
  
                                  if (index !== -1) {
                                    // If input_name already exists, update the existing lender data
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: prevState.lender.map((item, idx) =>
                                        idx === index
                                          ? { ...item, ...lender_data }
                                          : item
                                      ),
                                    }));
                                  } else {
                                    // If input_name does not exist, add the new lender data to the state
                                    this.setState((prevState) => ({
                                      loadButtonFlag: 1,
                                      lender: [...prevState.lender, lender_data],
                                    }));
                                  }
                                } else {
                                  const filteredConfig = this.state.lender.filter((item) => {
                                    return item.mapId !== configdata.report_config[0].mapId
                                  })
                                  this.state.lender = filteredConfig
                                  this.setState({
                                    loadButtonFlag: 0,
                                    lender: filteredConfig
                                  })
                                }
                              }
                            }
                            disabledDate={(current) => {
                              return (
                                current &&
                                (current <
                                  moment(
                                    configdata.report_config[0].default_start_dt
                                  ) ||
                                  current >
                                    moment().add(
                                      configdata.report_config[0].max_month,
                                      "months"
                                    ))
                              );
                            }}
                          />
                        </>
                      ) : configdata.report_config[0].inputtype == "textbox" ? (
                        <>
                          <span>{configdata.report_config[0].inputlabel}</span>
                          <input
                            className="form-control"
                            name={configdata.report_config[0].inputkey}
                            value={
                              this.state[configdata.report_config[0].inputkey]
                            }
                            onBlur={(e) => this.verifyinput(e, configdata.report_config[0])}
                          />
                          {this.state.isValidData == 0 ? (
                            <span className="errmsg">
                              {configdata.report_config[0].error_message}
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                )
              )}

              {(this.state.report_config.length > 0
                ? this.getInputLength(this.state.report_config[0].report_config)
                : this.state.loadButtonFlag == 1 &&
                  this.state.report_list != 1) && (
                <div className="col-md-2">
                  <span className="f18">&nbsp;</span>
                  <button
                    disabled={this.state.processed_AtCount > 0}
                    className={
                      this.state.processed_AtCount > 0
                        ? "form-control btn-primary p-2 btn-height disablesendreqbtn"
                        : "form-control btn-primary p-2 btn-height"
                    }
                    onClick={this.generate_report}
                  >
                    Send Request
                  </button>
                </div>
              )}
            </div>
            {report_data.length > 0 && (
              <div className="mt-4">
                <div className="float-right">
                  <button
                    className="btn btn-primary mb-1"
                    onClick={() => this.get_report_data()}
                  >
                    <ReloadOutlined />
                  </button>
                </div>
                <table className="table text-center">
                  <thead className="table-head">
                    <th>Request No.</th>
                    <th>Parameters Selected</th>
                    <th>Submitted At</th>
                    <th>Completed At</th>
                    <th>Download Link</th>
                  </thead>
                  <tbody>
                    {report_data.map((report) => (
                      <tr>
                        <td>{report.Request_No}</td>
                        <td>{this.get_inputdata(report.Parameter_Selected)}</td>
                        <td>
                          {moment(report.Submitted_At).format(
                            "DD-MMM-YY HH:mm"
                          )}
                        </td>
                        <td>
                          {report.completed_at &&
                            moment(report.completed_at).format(
                              "DD-MMM-YY HH:mm"
                            )}
                        </td>
                        <td>
                          {report.Download_Link ? (
                            this.get_hours(report.processed_at) <= 24 ? (
                              // <h6
                              //   className="download-link"
                              //   onClick={() =>
                              //     this.downloadReport(report.Download_Link)
                              //   }
                              // >
                              //   Download
                              // </h6>
                              <a
                                // href={report.Download_Link}
                                onClick={() => this.downloadReportUpdateDate(report.Request_No, report.Download_Link)}
                                className="download-link"
                              >
                                Download
                              </a>
                            ) : (
                              "Link Expired"
                            )
                          ) : (
                            "Processing Request"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

function gettimerdata(processed_at) {
  let future = Date.parse(processed_at);
  let now = new Date();
  let diff = future - now;

  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  let hours = Math.floor(diff / (1000 * 60 * 60));
  let mins = Math.floor(diff / (1000 * 60));
  let secs = Math.floor(diff / 1000);

  let d = days;
  let h = hours - days * 24;
  let m = mins - hours * 60;
  let s = secs - mins * 60;
  return d + ":" + h + " : " + m + " : " + s + "";
}
export default Report_list;
