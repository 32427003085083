import React, { Component, useState } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout, chkapiinput, checkTime, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import moment from "moment";
const generate_Id1 = generateId(10);
class Overnight_request extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      overnightdata: [],
      showDetailHistory: false,
      DetailHistory: [],
    };
  }
  componentDidMount() {
    document.title = "Overnight Request";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    this.getovernight();
  }
  getovernight() {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    //const formdata = new FormData();
    const uid = localStorage.getItem("in_userid");
    //formdata.append("in_userid", uid);
    let generate_Id = generateId(10);
    const params = {
      in_userid: localStorage.getItem("in_userid"),
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/fcm/list/requests?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
        //body: formdata,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          overnightdata: data.reverse(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  approveReq = (e) => {
    e.preventDefault();
    console.log("cash_balance_id", this.state.cash_balance_id);
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    const uid = localStorage.getItem("in_userid");
    formdata.append("in_userid", uid);
    formdata.append("status", "3");
    formdata.append("cash_balance_id", this.state.cash_balance_id);
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/fcm/process/to/request", {
      method: "POST",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
        user_id: localStorage.getItem("in_userid"),
      },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSuccess: true,
          Success_Message: data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showDetail(cbid) {
    this.setState({ isSpin: true });
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      cash_balance_id: cbid,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(
      process.env.REACT_APP_URL + "/review/denominations?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isSpin: false,
          DetailHistory: data,
          showDetailHistory: true,
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }
  render() {
    const {
      overnightdata,
      isSuccess,
      Success_Message,
      DetailHistory,
      showDetailHistory,
    } = this.state;
    return (
      <>
        <div
          className={
            showDetailHistory
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ showDetailHistory: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                <>
                  {DetailHistory.length > 0 ? (
                    <div className="dn-container mt-3 pr-3 row">
                      <div className="col-6 dn-header-text text-center">
                        Denom.
                      </div>

                      <div className="col-6 dn-header-text text-center">
                        Submitted
                      </div>
                      {DetailHistory.reverse().map((items) => (
                        <>
                          <div className="col-6 text-center mb-1">
                            <div className="dn-gre-amt">
                              {items.currency_value}
                            </div>
                          </div>
                          <div className="col-6 text-center mb-1">
                            <div className="dn-pri-amt">
                              {items.qty_submitted}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    "Data Not Found"
                  )}
                </>
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            isSuccess
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.getovernight();
                  this.setState({ isSuccess: false });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{Success_Message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.isApprove
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="slip-confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-xs">
            <div className="modal-content message-box col-sm-12">
              <div className="row">
                <div className="col-12">
                  <b>
                    <span>Are You Sure To Approve ?</span>
                  </b>
                </div>
              </div>

              {/* <div className="col-12"> */}
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={(e) => {
                    this.approveReq(e);
                    this.setState({
                      isApprove: false,
                    });
                  }}
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={() => {
                    this.setState({ isApprove: false });
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="top_nav">
            <a>
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="icon_img"
                />
              </div>
            </a>
            <span className="head-title">Overnight Hold Request</span>

            <a>
              <div className="log-out-deposit">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </a>
          </div>

          {overnightdata.length
            ? overnightdata.map((data, key) => {
                //const submitted_at = data.submitted_at.split(" ");
                return (
                  <div
                    className="hh_white_block"
                    key={key}
                    onClick={() => this.showDetail(data.id)}
                  >
                    <div className="reversal_trans_card_amount">
                      <span>{data.from_to}</span>
                      <span>Rs. {data.amt_submitted.toLocaleString()}</span>
                    </div>
                    <div className="reversal_trans_card_footer">
                      <ul>
                        <li>Date : {data.submitted_at}</li>
                        {/* <li>Time : {submitted_at[4]}</li> */}
                      </ul>
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              isApprove: true,
                              cash_balance_id: data.id,
                            });
                          }}
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </>
    );
  }
}
export default withRouter(Overnight_request);
