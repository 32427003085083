import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout, filterData, chkapiinput } from "./Utility";

class CsoDemandsheet extends Component {
  constructor() {
    super();
    this.state = {
      og_csodata: [],
      csodata: [],
      isLoaded: false,
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
      uniqueNames: [],
      uniqueLocation: [],
      searchModal: false,
      isShowName: true,
      isShowLoc: false,
      isShowDem: false,
      isShowBal: false,
      isShowCol: false,
      MenuName: "",
      filter: {
        household_name: [],
      },
      currentFilter: "household_name",
      filterDefaultCheck: [false, false, false, false, false, false, false],
      sortBy: "current_demand",
      sortMode: 0, //acending 0, decending 1.
    };
    this.doThis = this.doThis.bind(this);
    this.handleFilterMenu = this.handleFilterMenu.bind(this);
  }
  componentDidMount() {
    document.title = "CSO Demand Sheet";
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const { api_token, entity_id, entity_unit_level_id } =
      (this.props.location && this.props.location.state) || {};
    if (entity_id) {
      /*Check if data is null then logout*/
      var arr = [uid, entity_id, entity_unit_level_id];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function () {
          logout();
        }, 2000);
      }
/*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("in_userid", uid);
      formdata.append("in_entity_id", entity_id);
      formdata.append("in_entity_unit_level_id", entity_unit_level_id);
      customFetch(process.env.REACT_APP_URL + "/getreport", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          const uniqueNames = [
            ...new Set(json.map((item) => item.household_name)),
          ];
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }

          this.setState({
            isLoaded: true,
            og_csodata: json,
            csodata: json,
            isSpin: false,
            uniqueNames: uniqueNames,
          });
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    } else {
      const { api_token, max_unit_level_id } =
        (this.props.location && this.props.location.state) || {};
      /*Check if data is null then logout*/
      var arr = [uid];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function () {
          logout();
        }, 2000);
      }
/*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("in_userid", uid);
      formdata.append("call_procedure", uid);
      formdata.append("in_entity_unit_level_id", max_unit_level_id);
      customFetch(process.env.REACT_APP_URL + "/getreport", {
        method: "POST",
        headers: { "api-token": api_token },
        body: formdata,
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          const uniqueNames = [
            ...new Set(json.map((item) => item.household_name)),
          ];
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isLoaded: true,
            og_csodata: json,
            csodata: json,
            isSpin: false,
            uniqueNames: uniqueNames,
          });
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    }
  }

  doThis(entity_id, entity_name) {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    this.props.history.push({
      pathname: "/Division",
      state: {
        entity_id: entity_id,
        entity_name: entity_name,
        api_token: api_token,
      },
    });
  }

  handleFilterMenu(event) {
    let counter,
      MenuName,
      isShowName,
      isShowLoc,
      isShowDem,
      isShowBal,
      isShowCol,
      FilterName;
    if (event.currentTarget.id == "nameFilter") {
      counter = 1;
      MenuName = "Name";
      isShowName = true;
      isShowLoc = false;
      isShowDem = false;
      isShowBal = false;
      isShowCol = false;
      FilterName = "household_name";
    } else if (event.currentTarget.id == "locationFilter") {
      counter = 2;
      MenuName = "Location";
      isShowName = false;
      isShowLoc = true;
      isShowDem = false;
      isShowBal = false;
      isShowCol = false;
      FilterName = "location";
    } else if (event.currentTarget.id == "demandFilter") {
      counter = 3;
      MenuName = "Demand";
      isShowName = false;
      isShowLoc = false;
      isShowDem = true;
      FilterName = "current_demand";
      // isShowBal = false;
      // isShowCol = false;
    } else if (event.currentTarget.id == "balFilter") {
      counter = 4;
      MenuName = "Balance";
      isShowName = false;
      isShowLoc = false;
      isShowDem = true;
      FilterName = "balance";
      // isShowBal = true;
      // isShowCol = false;
    } else if (event.currentTarget.id == "colFilter") {
      counter = 5;
      MenuName = "Collection";
      isShowName = false;
      isShowLoc = false;
      isShowDem = true;
      FilterName = "collection";
      // isShowBal = false;
      // isShowCol = true;
    }
    this.setState({
      counter: counter,
      MenuName: MenuName,
      isShowName: isShowName,
      isShowLoc: isShowLoc,
      isShowDem: isShowDem,
      isShowBal: isShowBal,
      isShowCol: isShowCol,
      filter: {
        [FilterName]: [],
      },
      currentFilter: FilterName,
      filterDefaultCheck: [false, false, false, false, false, false, false],
    });
  }

  constructFilter = (e) => {
    const filter = { ...this.state.filter };
    const value = e.target.value;
    const currentFilter = this.state.currentFilter;

    if (e.target.name == "RangeValue") {
      const minmax = JSON.parse(value);
      const index = filter[currentFilter].findIndex((object) => {
        return object.id === minmax.id;
      });
      if (e.target.checked) {
        if (index == -1) {
          filter[currentFilter].push(minmax);
        }
      } else {
        if (index != -1) {
          filter[currentFilter].splice(index, 1);
        }
      }
    } else {
      if (e.target.checked) {
        if (!filter[currentFilter].includes(value)) {
          filter[currentFilter].push(value);
        }
      } else {
        const index = filter[currentFilter].indexOf(value);
        filter[currentFilter].splice(index, 1);
      }
    }
    this.setState({ filter });
  };

  handleFilter = (resetFlag) => {
    if (resetFlag) {
      this.setState({ csodata: this.state.og_csodata });
      return;
    }

    const keysWithMinMax = ["balance", "current_demand", "collection"];
    const filteredData = filterData(
      this.state.og_csodata,
      this.state.filter,
      keysWithMinMax
    );
    this.setState({ csodata: filteredData });
  };

  handleSortOptions = (e) => {
    if (e.target.name == "Mode") this.setState({ sortMode: e.target.value });
    else this.setState({ sortBy: e.target.value });
  };

  handleSort = () => {
    let csodata = this.state.csodata;
    let key = this.state.sortBy;
    if (this.state.sortMode == 0) {
      csodata.sort((a, b) => {
        return a[key] - b[key];
      });
    } else {
      csodata.sort((a, b) => {
        return b[key] - a[key];
      });
    }
    this.setState({ csodata });
  };

  handleCheckState = (i) => {
    let defaultCheck = this.state.filterDefaultCheck;
    defaultCheck[i] = !defaultCheck[i];
    this.setState({ filterDefaultCheck: defaultCheck });
  };

  handleSearch = (e) => {
    if (e.target.value == "") {
      this.setState({ csodata: this.state.og_csodata });
      return;
    }
    const csodata = this.state.og_csodata.filter((i) => {
      return (
        i.household_name.toUpperCase().match(e.target.value.toUpperCase()) ||
        i.household_number.toUpperCase().match(e.target.value.toUpperCase())
      );
    });
    this.setState({ csodata: csodata });
  };

  render() {
    const { entity_name } =
      (this.props.location && this.props.location.state) || {};
    const { csodata, isErrOpen, api_error_message } = this.state;

    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="sort-filter"
          ref={this.sortModal}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content sort_block col-sm-12">
              <span className="bluetext-header-sort">SORT BY</span>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Demand</td>
                    <td>
                      <input
                        type="radio"
                        className="form-check-input"
                        value="current_demand"
                        name="SortBy"
                        defaultChecked
                        onChange={this.handleSortOptions}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Balance</td>
                    <td>
                      <input
                        type="radio"
                        className="form-check-input"
                        value="balance"
                        name="SortBy"
                        onChange={this.handleSortOptions}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Collected</td>
                    <td>
                      <input
                        type="radio"
                        className="form-check-input"
                        value="collection"
                        name="SortBy"
                        onChange={this.handleSortOptions}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="sortBlueBox">
                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="Mode"
                    value={1}
                    onChange={this.handleSortOptions}
                  />
                  <label htmlFor="high">&nbsp;High To Low</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="htl"
                    name="Mode"
                    onChange={this.handleSortOptions}
                    value={0}
                    defaultChecked
                  />
                  <label htmlFor="low">&nbsp;Low To High</label>
                </div>
              </div>
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
                onClick={this.handleSort}
              >
                Apply
              </button>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="filter"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="filter_window">
                <div className="filter_header">
                  <table className="table filter-table">
                    <thead>
                      <tr>
                        <th>Filter</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="nameFilter"
                        className="unique-name-click"
                      >
                        <td>Name</td>
                      </tr>

                      <tr
                        onClick={this.handleFilterMenu}
                        id="locationFilter"
                        className="unique-name-click"
                      >
                        <td>Location</td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="demandFilter"
                        className="unique-name-click"
                      >
                        <td>Demand</td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="balFilter"
                        className="unique-name-click"
                      >
                        <td>Balance</td>
                      </tr>
                      <tr
                        onClick={this.handleFilterMenu}
                        id="colFilter"
                        className="unique-name-click"
                      >
                        <td>Collection</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="filter_amt" id="filter-sub-menu">
                  {this.state.isShowName && (
                    <div className="unique-names" id="uniqueNameDiv">
                      <center>
                        <strong>{this.state.MenuName}</strong>
                      </center>
                      <table className="table table-borderless">
                        {this.state.uniqueNames.map((item, key) => (
                          <tbody key={item}>
                            <tr>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  id={item}
                                  value={item}
                                  name="Name"
                                  onChange={this.constructFilter}
                                />
                                <label htmlFor="vehicle1"> &nbsp; {item}</label>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  )}

                  {this.state.isShowLoc && (
                    <div className="unique-names">
                      <center>
                        <strong>{this.state.MenuName}</strong>
                      </center>

                      <table className="table table-borderless">
                        {this.state.uniqueLocation.length > 0 ? (
                          this.state.uniqueLocation.map((item, key) => (
                            <tbody>
                              <tr>
                                <td>
                                  {" "}
                                  <input
                                    type="checkbox"
                                    id={item}
                                    name="Location"
                                    onChange={this.constructFilter}
                                  />
                                  <label htmlFor="vehicle1">
                                    {" "}
                                    &nbsp; {item}
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr>
                              <td>No Location</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  )}
                  {this.state.isShowDem && (
                    <>
                      <center>
                        <strong>{this.state.MenuName}</strong>
                      </center>
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(0);
                                  this.constructFilter(e);
                                }}
                                value='{"min": null, "max": 0,"id" :2}'
                                checked={this.state.filterDefaultCheck[0]}
                              />
                              <label htmlFor="vehicle1">
                                {" "}
                                &nbsp; Less Than 0
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(1);
                                  this.constructFilter(e);
                                }}
                                value='{"min": 0, "max": 0,"id" :3}'
                                checked={this.state.filterDefaultCheck[1]}
                              />
                              <label htmlFor="vehicle1"> &nbsp; Rs. 0 </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(2);
                                  this.constructFilter(e);
                                }}
                                value='{"min": 1, "max": 2000,"id" :4}'
                                checked={this.state.filterDefaultCheck[2]}
                              />
                              <label htmlFor="vehicle1">
                                {" "}
                                &nbsp; Rs. 1 - Rs. 2000
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(3);
                                  this.constructFilter(e);
                                }}
                                value='{"min": 2001, "max": 3999,"id" :5}'
                                checked={this.state.filterDefaultCheck[3]}
                              />
                              <label htmlFor="vehicle1">
                                {" "}
                                &nbsp;Rs. 2001 - Rs. 3999
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(4);
                                  this.constructFilter(e);
                                }}
                                value='{"min": 4000, "max": 6999,"id" :6}'
                                checked={this.state.filterDefaultCheck[4]}
                              />
                              <label htmlFor="vehicle1">
                                &nbsp; Rs. 4000 - Rs. 6999
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(5);
                                  this.constructFilter(e);
                                }}
                                value='{"min": 7000, "max": 9000,"id" :7}'
                                checked={this.state.filterDefaultCheck[5]}
                              />
                              <label htmlFor="vehicle1">
                                &nbsp; Rs. 7000 - Rs. 9000
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <input
                                type="checkbox"
                                name="RangeValue"
                                onChange={(e) => {
                                  this.handleCheckState(6);
                                  this.constructFilter(e);
                                }}
                                value='{"min": 9001, "max": null,"id" :8}'
                                checked={this.state.filterDefaultCheck[6]}
                              />
                              <label htmlFor="vehicle1">
                                &nbsp; Rs. 9001 - Above
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="filter-btn-div">
                    <button
                      data-dismiss="modal"
                      className="btn btn-outline-secondary  btn-md rbtn"
                      onClick={() => this.handleFilter(true)}
                    >
                      Reset
                    </button>
                    <button
                      data-dismiss="modal"
                      className="btn btn-primary  btn-md rbtn"
                      onClick={() => this.handleFilter(false)}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-sm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="search"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content search_block col-sm-12">
              {/* <form onSubmit={this.searchCustomer}> */}

              <input
                type="text"
                className="form-control rinput1 search_elements1"
                id="sfname"
                name="sfname"
                placeholder="Enter First Name or Location or Household ID"
                required
                onChange={this.handleSearch}
              />
              <button
                data-dismiss="modal"
                type="submit"
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
              >
                Search
              </button>
              {/* </form> */}
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="icon_img"
                  alt=""
                />
              </div>
              <span className="head-title">View Reports</span>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="filter_button_container">
              <a data-toggle="modal" data-target="#sort-filter">
                <div className="icon_bg">
                  <img
                    src={require("../assets/images/list.png")}
                    className="icon_img"
                  />
                </div>
              </a>
              <a data-toggle="modal" data-target="#filter">
                <div className="icon_bg">
                  <img
                    src={require("../assets/images/sort.png")}
                    className="icon_img"
                  />
                </div>
              </a>
              <a data-toggle="modal" data-target="#search">
                <div className="icon_bg">
                  <img
                    src={require("../assets/images/search.png")}
                    className="icon_img"
                  />
                </div>
              </a>
            </div>
            <div className="sdiv">
              <span className="stext">DEMAND SHEET HOUSEHOLD</span>
            </div>
            <div className="cname_block_csodemand">
              <div className="cust_name">
                <span className="cname">{entity_name}</span>
              </div>
            </div>
            {csodata.length > 0 ? (
              csodata.map((item) => (
                <div className="info_box mt-4 mb-12" key={item.household_id}>
                  <div className="cname_loan_info_block">
                    <div className="d-flex align-items-center">
                      {item.nach_registered_flag === 1 ? (
                        <img
                          src={require("../assets/images/nach.png")}
                          alt=""
                          className="nach-img"
                        />
                      ) : (
                        ""
                      )}
                      <span className="name_cds mx-3">
                        {item.household_name}
                      </span>
                    </div>
                    <div className="d-flex justify-content-around">
                      <button className="msg_icon_bg mx-1">
                        <img
                          src={require("../assets/images/comment.png")}
                          className="msg_icon_img"
                        />
                      </button>
                      <button className="call_icon_bg mx-1">
                        <img
                          src={require("../assets/images/telephone (2).png")}
                          className="call_icon_img"
                        />
                      </button>
                    </div>

                    {/* <div className="right_side">
                    <img
                      src={require("../assets/images/phone-call.png")}
                      alt=""
                    />
                  </div> */}
                  </div>
                  <div className="row mx-3">
                    <span className="loan_id">
                      HH Id: {item.household_number}
                    </span>
                  </div>
                  <div className="charges_block">
                    <div className="charges_desc">
                      <span>Installment</span>
                    </div>
                    <div className="charges">
                      <span>
                        Rs. {parseFloat(item.emi).toLocaleString("en-IN")}
                      </span>
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div className="charges_block">
                    <div className="charges_desc">
                      <span>Charges</span>
                    </div>
                    <div className="charges">
                      <span>
                        Rs. {parseFloat(item.charges_os).toLocaleString("en-IN")}
                      </span>
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div className="charges_block">
                    <div className="charges_desc">
                      <span>Total Due</span>
                    </div>
                    <div className="charges">
                      <span>
                        Rs. {parseFloat(item.tot_due).toLocaleString("en-IN")}
                      </span>
                    </div>
                  </div>

                  <div className="col_bal_block">
                    <div className="collected">
                      Collected
                      <div className="col_block">
                        Rs.{" "}
                        {parseFloat(item.collection).toLocaleString("en-IN")}
                      </div>
                    </div>

                    <div className="balanced">
                      Balance
                      <div className="bal_block">
                        Rs. {parseFloat(item.balance).toLocaleString("en-IN")}
                      </div>
                    </div>
                  </div>
                  {item.nach_registered_flag === 1 ? (
                    <div className="tot_fail_block">
                      <div className="foot-label">Last 6 Month</div>
                      <div className="d-flex">
                        <div className="tf_left_side">
                          <img
                            src={require("../assets/images/accept.png")}
                            alt=""
                          />{" "}
                          TOTAL: {item.success_cnt}
                        </div>
                        <div className="tf_right_side">
                          <img
                            src={require("../assets/images/remove.png")}
                            alt=""
                          />{" "}
                          FAIL: {item.failure_cnt}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))
            ) : (
              <span>No results found</span>
            )}
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(CsoDemandsheet);
