import React, { Component } from "react";
import customFetch from "./apicall/api";
import { generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
const generate_Id1 = generateId(10);
export class Phone_OTP_Verify extends Component {
  static contextType = ThemeContext;
  constructor() {
    super();
    this.state = {
      isEnable: false,
      isSpin: false,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      canResend: false,
      time: {
        minutes: 0,
        seconds: 0,
      },
      duration: 10 * 60 * 1000,
      timer: null,
      isErrOpen: false,
      api_error_message: "",
      OTPErr: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleotpSubmit = this.handleotpSubmit.bind(this);
    this.startTimer = this.start.bind(this);
  }
  handleChange(event) {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    this.setState({
      [event.target.name]: event.target.value,
    });
    event.preventDefault();
  }
  componentDidMount() {
    document.title = "Phone OTP Verify";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    this.start();
  }
  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);

    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    return {
      minutes,
      seconds,
    };
  }
  start() {
    if (!this.state.timer) {
      this.state.startTime = Date.now();
      this.timer = window.setInterval(() => this.run(), 10);
    }
  }
  run() {
    const diff = Date.now() - this.state.startTime;

    let remaining = this.state.duration - diff;
    if (remaining < 0) {
      remaining = 0;
    }
    this.setState(() => ({
      time: this.msToTime(remaining),
    }));
    if (remaining === 0) {
      window.clearTimeout(this.timer);
      this.timer = null;
      this.setState({ canResend: true });
    }
  }
  handleotpSubmit(event) {
    this.setState({
      isSpin: true,
      isEnable: true,
    });
    const { otp1, otp2, otp3, otp4, otp5, otp6 } = this.state;
    const { phone, latitude, longitude, userid } =
      (this.props.location && this.props.location.state) || {};
    const ftoken = localStorage.getItem("currentToken");
    event.preventDefault();
    const formdata2 = new FormData();
    formdata2.append("in_client_ip", "");
    formdata2.append("in_latitude", latitude);
    formdata2.append("in_longitude", longitude);
    formdata2.append("in_gps_address", latitude + "," + longitude);
    formdata2.append("otp_source", phone);
    formdata2.append("otp", otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
    formdata2.append("in_userid", userid);
    formdata2.append("firebase_token", ftoken);
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/login", {
      method: "POST",
      body: formdata2,
      headers: {
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
      },
    })
      .then((res2) => {
        if (res2.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res2.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res2.json();
      })
      .then((data2) => {
        if (
          data2.api_code === 4 ||
          data2.api_code === 3 ||
          data2.api_code === 5
        ) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log("action_message", data2.action_message);
        console.log(data2.sendotp);
        if (data2.logged_in === true) {
          localStorage.setItem("in_userid", data2.in_userid);
          localStorage.setItem("in_username", data2.in_username);
          localStorage.setItem("phone_no", data2.phone_no);
          localStorage.setItem("employee_name", data2.employee_name);
          this.props.history.push({
            pathname: "/Dashboard",
            state: { api_token: data2.api_token },
          });
        } else {
          this.setState({
            isSpin: false,
            isEnable: false,
            OTPErr: data2.action_message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }
  handleResendButtonClick = (e) => {
    e && e.preventDefault();
    //this.resetTimer()
    const { phone } = (this.props.location && this.props.location.state) || {};
    this.setState({ canResend: false, isSpin: true });
    this.start();
    const formdata1 = new FormData();
    formdata1.append("user_mobile_no", phone);
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/delotp", {
      method: "POST",
      body: formdata1,
      headers: {
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: this.constructor.name,
      },
    })
      .then((res1) => {
        if (res1.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res1.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res1.json();
      })
      .then((data1) => {
        if (
          data1.api_code === 4 ||
          data1.api_code === 3 ||
          data1.api_code === 5
        ) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log(data1.sendotp);
        if (data1.sendotp === true) {
          const formdata2 = new FormData();
          formdata2.append("user_mobile_no", phone);
          let generate_Id = generateId(10);
          customFetch(process.env.REACT_APP_URL + "/sendotp", {
            method: "POST",
            body: formdata2,
            headers: {
              component_id: generate_Id1,
              apiId: generate_Id,
              api_group_id: "",
              component: this.constructor.name,
            },
          })
            .then((res2) => {
              if (res2.headers.get("api_code") > 0) {
                this.setState({
                  isErrOpen: true,
                  api_error_message: res2.headers.get("api_error_message"),
                  isSpin: false,
                });
              }
              return res2.json();
            })
            .then((data2) => {
              if (
                data2.api_code === 4 ||
                data2.api_code === 3 ||
                data2.api_code === 5
              ) {
                localStorage.clear();
                this.props.history.push("/");
              }
              console.log(data2.sendotp);
              if (data2.sendotp === true) {
                this.setState({
                  successmsg: "OTP Send Success!",
                  isSpin: false,
                });
                //this.start()
                alert("OTP Send Success!");
              }
            });
        }
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  };
  render() {
    const { phone } = (this.props.location && this.props.location.state) || {};
    const { isErrOpen, api_error_message } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="col images">
              <div className="logo">
                <img
                  src={require("../assets/images/aceN Logo.png")}
                  alt="Logo"
                />
              </div>
              <div className="user">
                <img
                  src={require("../assets/images/OTP_Verify_BG.png")}
                  alt="User-with-phone"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center title">
              Verification Code
            </div>
            <div className="text-center title_text">
              <p>We have sent the code verification to</p>
              <p>Your Mobile Number</p>
            </div>

            <div className="d-flex mob_number">
              <div className="mob">{phone}</div>
              {/* <div className="icon_pen">
                <img
                  src={require("../assets/images/edit.png")}
                  alt="User-with-phone"
                />
              </div> */}
            </div>

            <form onSubmit={this.handleotpSubmit} className="otp-form">
              <input
                name="otp1"
                placeholder="0"
                value={this.state.otp1}
                onChange={this.handleChange}
                className="otp"
                maxLength={1}
              />
              <input
                name="otp2"
                placeholder="0"
                value={this.state.otp2}
                onChange={this.handleChange}
                className="otp"
                maxLength={1}
              />
              <input
                name="otp3"
                placeholder="0"
                value={this.state.otp3}
                onChange={this.handleChange}
                className="otp"
                maxLength={1}
              />
              <input
                name="otp4"
                placeholder="0"
                value={this.state.otp4}
                onChange={this.handleChange}
                className="otp"
                maxLength={1}
              />
              <input
                name="otp5"
                placeholder="0"
                value={this.state.otp5}
                onChange={this.handleChange}
                className="otp"
                maxLength={1}
              />
              <input
                name="otp6"
                placeholder="0"
                value={this.state.otp6}
                onChange={this.handleChange}
                className="otp"
                maxLength={1}
              />
              <div className="text-center email-error">{this.state.OTPErr}</div>
              <div className="form-group">
                <div className="text-center">
                  <button
                    className="btn btn-lg btn-primary email-submit"
                    onClick={this.handleResendButtonClick}
                    disabled={!this.state.canResend}
                  >
                    {!this.state.canResend
                      ? this.state.time.minutes + ":" + this.state.time.seconds
                      : "Resend OTP"}
                  </button>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary email-submit"
                    disabled={this.state.isEnable}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </>
    );
  }
}

export default Phone_OTP_Verify;
