import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { logout, chkapiinput, generateId } from "./Utility";
import { Rate } from "antd";
import { ThemeContext } from "../context/IsLoader";
const generate_Id1 = generateId(10);
class SupportTicketList extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      tickets: [],
      jira_tickets: [],
      unsorted_tickets: [],
      user_id: localStorage.getItem("in_userid"),
      status: ["Resolved", "In Queue", "In Work", "In Review"],
      categories: [
        "Datacorrection",
        "Feature/functionalitynotworking",
        "Appdown",
        "Login/role/access/mapping issue",
        "Reports/MISrelated",
        "Query/clarification",
      ],
      search_text: "",
      filters: [],
      isSpin: false,
      flag: 0,
      date: new Date(),
      api_token: localStorage.getItem("api_token"),
    };
  }

  async fetchDetails() {
    this.setState({ isSpin: true });
    /*Check if data is null then logout*/
    var arr = [this.state.user_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }

    const params = {
      user_id: this.state.user_id,
    };
    const queryParams = new URLSearchParams(params).toString();

    const _headers = new Headers();
    _headers.append("Content-Type", "multipart/form-data");
    let generate_Id = generateId(10);
    const res = await customFetch(
      process.env.REACT_APP_URL + "/get/jira/ticket/user?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    );
    let tickets = await res.json();
    tickets.sort(function(a, b) {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    let _jira_tickets = [];
    if (tickets.length === 0) {
      this.setState({ isSpin: false });
    }
    tickets.forEach(async (ticket) => {
      try {
        let data = await this.fetchTickets(ticket);
        data["issue_key"] = ticket.issue_key;
        data["rating"] = ticket.rating;
        if (data.status == "Done") {
          data.status = this.state.status[0];
          data["status_color"] = "ticket_status_resolved";
        }
        if (data.status == "To Do") {
          data.status = this.state.status[1];
          data["status_color"] = "ticket_status_inqueue";
        }
        if (data.status == "In Progress") {
          data.status = this.state.status[2];
          data["status_color"] = "ticket_status_inwork";
        }
        _jira_tickets.push(data);
        _jira_tickets.sort(function(a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        this.setState({
          jira_tickets: _jira_tickets,
          unsorted_tickets: _jira_tickets,
          isSpin: false,
          // flag: 0,
        });
      } catch (e) {
        console.error(e);
        this.setState({
          isSpin: false
        })
      }
    });
    //this.setState({ isSpin: false });
  }

  async fetchTickets(ticket) {
    /*Check if data is null then logout*/
    var arr = [ticket.issue_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }

    const params = {
      ticket_no: ticket.issue_id,
    };
    const queryParams = new URLSearchParams(params).toString();

    const _headers = new Headers();
    _headers.append("Content-Type", "multipart/form-data");
    let generate_Id = generateId(10);
    const response = await customFetch(
      process.env.REACT_APP_URL + "/get/jira/ticket/details?" + queryParams,
      {
        method: "GET",
        headers: {
          "api-token": this.state.api_token,
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: this.constructor.name,
          user_id: localStorage.getItem("in_userid"),
        },
      }
    );

    if (response.status != 200) throw "Internal Server Error";
    let data = await response.json();
    return data;
  }
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: this.constructor.name,
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
    console.log(this.state);
  }

  // logout = () => {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // };

  // calculateDateDiff(created_on){
  //     return  new Date().getDate() - new Date(created_on).getDate();
  // }

  handleSearchInput = (e) => {
    this.setState({ search_text: e.target.value.toUpperCase() });
  };

  onSearch = () => {
    const _jira_tickets = this.state.unsorted_tickets.filter((i) => {
      return i.issue_key.match(this.state.search_text);
    });
    this.setState({ jira_tickets: _jira_tickets });
  };

  onFilter = () => {
    // const filters = [{"type":"label","filter":"Appdown"},{"type":"label","filter":"Query/clarification"},{"type":"status","filter":"In Queue"},{"type":"status","filter":"Resolved"}];
    const filters = this.state.filters;
    console.log(filters);

    if (!filters.length) {
      let _jira_tickets = this.state.unsorted_tickets.filter((i) => {
        if (new Date(i.created_at) <= this.state.date) return i;
      });

      this.setState({ jira_tickets: _jira_tickets });
      return;
    }

    let _status_filter = filters.some((el) => {
      if (el.type == "status") return true;
      else return false;
    });

    let _category_filter = filters.some((el) => {
      if (el.type == "label") return true;
      else return false;
    });

    let _jira_tickets_category = this.state.unsorted_tickets.filter((i) => {
      for (let j = 0; j < filters.length; j++) {
        if (filters[j]["type"] == "label")
          if (i[filters[j]["type"]].match(filters[j]["filter"]))
            return i[filters[j]["type"]].match(filters[j]["filter"]);
      }
    });

    let _jira_tickets_status = this.state.unsorted_tickets.filter((i) => {
      for (let j = 0; j < filters.length; j++) {
        if (filters[j]["type"] == "status")
          if (i[filters[j]["type"]].match(filters[j]["filter"]))
            return i[filters[j]["type"]].match(filters[j]["filter"]);
      }
    });

    let _jira_tickets = [];
    if (_status_filter && _category_filter) {
      _jira_tickets = _jira_tickets_status.filter((value) =>
        _jira_tickets_category.includes(value)
      );
    } else {
      _jira_tickets = _jira_tickets_status.concat(_jira_tickets_category);
    }

    _jira_tickets = _jira_tickets.filter((i) => {
      if (new Date(i.created_at) <= this.state.date) return i;
    });

    this.setState({ jira_tickets: _jira_tickets });
  };

  setFilter = (e, type) => {
    let _filters = this.state.filters;
    if (e.target.checked) {
      _filters.push({ type: type, filter: e.target.id });
    } else {
      for (let i = 0; i < _filters.length; i++) {
        if (e.target.id == _filters[i]["filter"]) {
          _filters.splice(i, 1);
        }
      }
    }
    console.log(_filters);
    this.setState({ filters: _filters });
  };

  setDate = (e) => {
    const new_date = new Date(e.target.value);
    this.setState({ date: new_date });
    console.log(this.state.date);
  };

  clear = () => {
    this.setState({
      status: ["Resolved", "In Queue", "In Work", "In Review"],
      categories: [
        "Datacorrection",
        "Feature/functionalitynotworking",
        "Appdown",
        "Login/role/access/mapping issue",
        "Reports/MISrelated",
        "Query/clarification",
      ],
      jira_tickets: this.state.unsorted_tickets,
      search_text: "",
    });
  };

  created_at = (date) => {
    const formatter = new Intl.RelativeTimeFormat("en");
    let diff = (new Date() - date) / 1000;

    const datediff = { diff: diff, type: "second" };

    if (datediff.diff >= 60) {
      datediff.diff = datediff.diff / 60;
      datediff.type = "minutes";
      if (datediff.diff >= 60) {
        datediff.diff = datediff.diff / 60;
        datediff.type = "hours";
        if (datediff.diff >= 24) {
          datediff.diff = datediff.diff / 24;
          datediff.type = "days";
          if (datediff.diff >= 7) {
            datediff.diff = datediff.diff / 7;
            datediff.type = "weeks";
          }
        }
      }
    }
    let x = formatter.format(-parseInt(datediff.diff, 10), datediff.type);
    return x;
  };
  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container support_ticket_list">
        {this.state.flag === 0 && (
          <div className="support_list_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} />
            </button>
            <span>Support Ticket List</span>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>
        )}

          <div className="support_list_filters">
            <div className="dropdown">
              <button data-toggle="dropdown">
                Status
                <img src={require("../assets/images/drop-menu-icon.png")} />
              </button>
              <div className="dropdown-menu">
                {this.state.status.map((item, index) => {
                  return (
                    <label
                      className="dropdown-item d-flex align-items-center mt-0 mb-0"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      htmlFor={item}
                      key={index}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => this.setFilter(e, "status")}
                        id={item}
                      />
                      <span style={{ marginLeft: "10px" }}>{item}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="dropdown">
              <button data-toggle="dropdown">
                Category
                <img src={require("../assets/images/drop-menu-icon.png")} />
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                {this.state.categories.map((item, index) => {
                  return (
                    <label
                      className="dropdown-item d-flex align-items-center mt-0 mb-0"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      htmlFor={item}
                      key={index}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => this.setFilter(e, "label")}
                        id={item}
                      />
                      <span style={{ marginLeft: "10px" }}>{item}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="dropdown">
              <button data-toggle="dropdown">
                Date
                <img src={require("../assets/images/drop-menu-icon.png")} />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <label
                  htmlFor="date_filter"
                  className="dropdown-item d-flex align-items-center mt-0 mb-0"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <input
                    type="date"
                    id="date_filter"
                    max={`${new Date().toISOString().split("T")[0]}`}
                    onChange={this.setDate}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={this.onFilter}
            >
              Filter
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={this.clear}
            >
              Clear
            </button>
          </div>
          <div className="support_list_search_box">
            <span>Enter Ticket Number</span>
            <input
              type="text"
              value={this.state.search_text}
              onChange={this.handleSearchInput}
            />
            <button
              className="btn btn-primary rbtn btn-lg col-8 col-md-6 col-lg-4"
              onClick={this.onSearch}
            >
              Search
            </button>
          </div>
          {this.state.jira_tickets.length ? (
            this.state.jira_tickets.map((ticket) => {
              let created_at = this.created_at(new Date(ticket.created_at));

              return (
                <div
                  className="support_list_ticket_preview"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/ticketdetails",
                      state: {
                        issue_key: ticket.issue_key,
                      },
                    })
                  }
                  key={ticket.issue_key}
                >
                  <span className="support_list_ticket_id d-flex align-items-center justify-content-between">
                    Ticket : {ticket.issue_key}
                    {ticket.rating ? (
                      <Rate
                        disabled
                        value={ticket.rating}
                        style={{
                          display: "flex",
                          marginBottom: 20,
                          justifyContent: "space-around",
                          transform: "scale(0.8)",
                        }}
                      ></Rate>
                    ) : (
                      ""
                    )}
                  </span>
                  <span className="support_list_ticket_summary">
                    {ticket.summary}
                  </span>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="support_list_ticket_created_at">
                      created at, {created_at}
                    </span>
                    <div
                      className={`support_list_ticket_status ${ticket.status_color}`}
                    >
                      {ticket.status}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No results</div>
          )}
        </div>
      </>
    );
  }
}

export default SupportTicketList;
